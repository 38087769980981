import * as React from 'react';
export const EmptinessIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={63}
    height={89}
    fill="none"
    viewBox="0 0 63 89"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M12.5042 39.7565c-1.2925-1.6617-6.2315-2.3079-6.2315-2.3079s3.6216-.5503 5.7699-2.308c2.5387-2.0771 2.5387-7.6162 2.5387-7.6162s.6924 5.3083 2.308 7.1546c1.6156 1.8464 6.9239 2.308 6.9239 2.308s-5.4468.5539-6.9239 2.7695c-1.4771 2.2157-2.308 7.3855-2.308 7.3855s-.7847-5.7238-2.0771-7.3855ZM43.6217 26.5512c-1.5756-2.5202-7.597-3.5002-7.597-3.5002s4.4153-.8346 7.0343-3.5003C46.1541 16.4005 46.1541 8 46.1541 8s.8441 8.0505 2.8137 10.8507c1.9696 2.8002 8.4412 3.5002 8.4412 3.5002s-6.6404.8401-8.4412 4.2003c-1.8008 3.3602-2.8137 11.2008-2.8137 11.2008s-.9567-8.6806-2.5324-11.2008Z"
    />
    <path
      fill="#1D1D1D"
      stroke="#1D1D1D"
      strokeWidth={3}
      d="M32.0454 84.106c7.1174 0 13.6067-.6399 18.3566-1.6954 2.3628-.5251 4.3771-1.1705 5.8372-1.9376.7285-.3828 1.3925-.8335 1.8916-1.3755.5065-.5501.9146-1.2779.9146-2.1581 0-.8802-.4081-1.6081-.9146-2.1581-.4991-.5421-1.1631-.9928-1.8916-1.3755-1.4601-.7672-3.4744-1.4126-5.8372-1.9376-4.7499-1.0556-11.2392-1.6955-18.3566-1.6955s-13.6067.6399-18.3566 1.6955c-2.3628.525-4.377 1.1704-5.8371 1.9376-.7285.3827-1.3925.8334-1.8917 1.3755-.5065.55-.9146 1.2779-.9146 2.1581 0 .8802.4081 1.608.9146 2.1581.4992.542 1.1632.9927 1.8917 1.3755 1.46.7671 3.4743 1.4125 5.8371 1.9376 4.7499 1.0555 11.2392 1.6954 18.3566 1.6954Z"
    />
    <path
      fill="#F4F5F7"
      fillRule="evenodd"
      d="M57.0353 78.0728c.3345-.3662.5101-.7453.5101-1.1334 0-3.1296-11.4167-5.6667-25.5-5.6667s-25.5 2.5371-25.5 5.6667c0 .3881.1756.7671.5101 1.1333v.0001c2.3628-2.5865 12.6533-4.5333 24.9899-4.5333 12.3366 0 22.6271 1.9468 24.9899 4.5333Z"
      clipRule="evenodd"
    />
  </svg>
);
