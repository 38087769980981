import * as React from 'react';
export const TransferTfsoIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={307}
    height={201}
    fill="none"
    viewBox="0 0 307 201"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#3B2D81"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m6.4034 106.994 4.3555 12.397c.4397 1.251 1.6493 2.065 2.9743 2.002L115.5 116.5l-32.9386-13.282a2.9963 2.9963 0 0 0-1.1219-.218H9.2338c-2.068 0-3.5159 2.043-2.8304 3.994Z"
    />
    <path
      fill="#3B2D81"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m6.4034 106.994 4.3555 12.397c.4397 1.251 1.6493 2.065 2.9743 2.002L115.5 116.5l-32.9386-13.282a2.9963 2.9963 0 0 0-1.1219-.218H9.2338c-2.068 0-3.5159 2.043-2.8304 3.994ZM300.597 106.994l-4.356 12.397a3.0013 3.0013 0 0 1-2.974 2.002L191.5 116.5l32.939-13.282c.356-.144.737-.218 1.122-.218h72.205c2.068 0 3.516 2.043 2.831 3.994Z"
    />
    <path
      fill="#3B2D81"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m300.597 106.994-4.356 12.397a3.0013 3.0013 0 0 1-2.974 2.002L191.5 116.5l32.939-13.282c.356-.144.737-.218 1.122-.218h72.205c2.068 0 3.516 2.043 2.831 3.994Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M135.893 16H92.107C90.9433 16 90 16.9433 90 18.107v55.786c0 1.1637.9433 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V18.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M126.5 27.5h-25M126.5 36.5h-25M126.5 45.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M135.893 16H92.107C90.9433 16 90 16.9433 90 18.107v55.786c0 1.1637.9433 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V18.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M126.5 27.5h-25M126.5 36.5h-25M126.5 45.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M146.893 5h-43.786C101.943 5 101 5.9433 101 7.107v55.786c0 1.1637.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V7.107C149 5.9433 148.057 5 146.893 5Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M137.5 16.5h-25M137.5 25.5h-25M137.5 34.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M146.893 5h-43.786C101.943 5 101 5.9433 101 7.107v55.786c0 1.1637.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V7.107C149 5.9433 148.057 5 146.893 5Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M137.5 16.5h-25M137.5 25.5h-25M137.5 34.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M247.893 24h-43.786c-1.164 0-2.107.9433-2.107 2.107v55.786c0 1.1637.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V26.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M238.5 35.5h-25M238.5 44.5h-25M238.5 53.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M247.893 24h-43.786c-1.164 0-2.107.9433-2.107 2.107v55.786c0 1.1637.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V26.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M238.5 35.5h-25M238.5 44.5h-25M238.5 53.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M86.893 96H43.107C41.9433 96 41 96.9433 41 98.107v55.786c0 1.164.9433 2.107 2.107 2.107h43.786c1.1637 0 2.107-.943 2.107-2.107V98.107C89 96.9433 88.0567 96 86.893 96Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M77.5 107.5h-25M77.5 116.5h-25M77.5 125.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M86.893 96H43.107C41.9433 96 41 96.9433 41 98.107v55.786c0 1.164.9433 2.107 2.107 2.107h43.786c1.1637 0 2.107-.943 2.107-2.107V98.107C89 96.9433 88.0567 96 86.893 96Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M77.5 107.5h-25M77.5 116.5h-25M77.5 125.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M268.893 73h-43.786c-1.164 0-2.107.9433-2.107 2.107v55.786c0 1.164.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.943 2.107-2.107V75.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M259.5 84.5h-25M259.5 93.5h-25M259.5 102.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M268.893 73h-43.786c-1.164 0-2.107.9433-2.107 2.107v55.786c0 1.164.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.943 2.107-2.107V75.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M259.5 84.5h-25M259.5 93.5h-25M259.5 102.5h-25"
    />
    <path
      fill="#DED8FA"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m8.407 103.373 15.7037 15.547A3.001 3.001 0 0 1 25 121.052v68.092c0 2.755-3.4004 4.053-5.2362 2L5.7637 175.487A2.9998 2.9998 0 0 1 5 173.488v-68.694c0-1.777 2.1443-2.671 3.407-1.421Z"
    />
    <path
      fill="#DED8FA"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m8.407 103.373 15.7037 15.547A3.001 3.001 0 0 1 25 121.052v68.092c0 2.755-3.4004 4.053-5.2362 2L5.7637 175.487A2.9998 2.9998 0 0 1 5 173.488v-68.694c0-1.777 2.1443-2.671 3.407-1.421ZM298.593 103.373l-15.704 15.547a3.001 3.001 0 0 0-.889 2.132v68.092c0 2.755 3.4 4.053 5.236 2l14-15.657c.492-.55.764-1.262.764-1.999v-68.694c0-1.777-2.144-2.671-3.407-1.421Z"
    />
    <path
      fill="#DED8FA"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m298.593 103.373-15.704 15.547a3.001 3.001 0 0 0-.889 2.132v68.092c0 2.755 3.4 4.053 5.236 2l14-15.657c.492-.55.764-1.262.764-1.999v-68.694c0-1.777-2.144-2.671-3.407-1.421Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M113.893 116H22.7192c-1.1637 0-2.107.943-2.107 2.107v71.786c0 1.164.9433 2.107 2.107 2.107h91.1738c1.164 0 2.107-.943 2.107-2.107v-71.786c0-1.164-.943-2.107-2.107-2.107Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M113.893 116H22.7192c-1.1637 0-2.107.943-2.107 2.107v71.786c0 1.164.9433 2.107 2.107 2.107h91.1738c1.164 0 2.107-.943 2.107-2.107v-71.786c0-1.164-.943-2.107-2.107-2.107ZM193.107 116h91.174c1.163 0 2.107.943 2.107 2.107v71.786c0 1.164-.944 2.107-2.107 2.107h-91.174c-1.164 0-2.107-.943-2.107-2.107v-71.786c0-1.164.943-2.107 2.107-2.107Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M193.107 116h91.174c1.163 0 2.107.943 2.107 2.107v71.786c0 1.164-.944 2.107-2.107 2.107h-91.174c-1.164 0-2.107-.943-2.107-2.107v-71.786c0-1.164.943-2.107 2.107-2.107Z"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M187.893 9h-19.786c-1.164 0-2.107.9433-2.107 2.107v19.786c0 1.1637.943 2.107 2.107 2.107h19.786c1.164 0 2.107-.9433 2.107-2.107V11.107C190 9.9433 189.057 9 187.893 9Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M173 20.6267 177.125 25 184 17"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M187.893 9h-19.786c-1.164 0-2.107.9433-2.107 2.107v19.786c0 1.1637.943 2.107 2.107 2.107h19.786c1.164 0 2.107-.9433 2.107-2.107V11.107C190 9.9433 189.057 9 187.893 9Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M173 20.6267 177.125 25 184 17"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M77.893 44H58.107C56.9433 44 56 44.9433 56 46.107v19.786c0 1.1637.9433 2.107 2.107 2.107h19.786C79.0567 68 80 67.0567 80 65.893V46.107C80 44.9433 79.0567 44 77.893 44Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M63 55.6267 67.125 60 74 52"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M77.893 44H58.107C56.9433 44 56 44.9433 56 46.107v19.786c0 1.1637.9433 2.107 2.107 2.107h19.786C79.0567 68 80 67.0567 80 65.893V46.107C80 44.9433 79.0567 44 77.893 44Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M63 55.6267 67.125 60 74 52"
    />
    <path
      fill="#fff"
      d="M76.1202 144.942v26.827H56v-31.298c0-1.186.4711-2.323 1.3096-3.161a4.4694 4.4694 0 0 1 3.1616-1.31"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.236}
      d="M76.1202 144.942v26.827H56v-31.298c0-1.186.4711-2.323 1.3096-3.161a4.4694 4.4694 0 0 1 3.1616-1.31"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.236}
      d="M56 156.12c0-1.186.4711-2.323 1.3096-3.162a4.4726 4.4726 0 0 1 3.1616-1.309"
    />
    <path
      fill="#BDB2F5"
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.236}
      d="M85.0625 144.942H64.9423v-4.471c0-1.186-.4711-2.323-1.3096-3.161a4.4693 4.4693 0 0 0-3.1615-1.31h20.1201c1.1859 0 2.3231.471 3.1616 1.31a4.4687 4.4687 0 0 1 1.3096 3.161v4.471Z"
    />
    <path
      fill="#FDC7BD"
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.236}
      d="M85.0625 160.591H64.9423v-4.471c0-1.186-.4711-2.323-1.3096-3.162a4.4726 4.4726 0 0 0-3.1615-1.309h20.1201c1.1859 0 2.3231.471 3.1616 1.309a4.4727 4.4727 0 0 1 1.3096 3.162v4.471Z"
    />
    <path
      fill="#8C77EE"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M165.893 72h-33.786c-1.164 0-2.107.9433-2.107 2.107v19.786c0 1.1637.943 2.107 2.107 2.107h33.786c1.164 0 2.107-.9433 2.107-2.107V74.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      fill="#8C77EE"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M165.893 72h-33.786c-1.164 0-2.107.9433-2.107 2.107v19.786c0 1.1637.943 2.107 2.107 2.107h33.786c1.164 0 2.107-.9433 2.107-2.107V74.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M139 83.5h20M154 78l5.5 5.5L154 89M139 83.5h20"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="m154 78 5.5 5.5L154 89"
    />
    <path
      fill="#1D1D1D"
      d="M213.594 173.349c0-6.443 5.218-11.715 11.597-11.715h11.597v11.715h-23.194ZM254.828 135.6v7.81h-7.732v7.81h-7.731v7.811h23.194V135.6h-7.731ZM225.191 135.6c-6.379 0-11.597 5.272-11.597 11.715h11.597v11.716c6.378 0 11.597-5.272 11.597-11.716 0-6.443-5.219-11.715-11.597-11.715ZM256.761 173.349c3.202 0 5.798-2.622 5.798-5.858 0-3.235-2.596-5.857-5.798-5.857-3.203 0-5.799 2.622-5.799 5.857 0 3.236 2.596 5.858 5.799 5.858Z"
    />
  </svg>
);
