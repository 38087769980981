import * as React from 'react';
export const BabySealOfApprovalIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={133}
    height={134}
    fill="none"
    viewBox="0 0 133 134"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#1D1D1D"
      d="M63.8383 132.791c28.7313 0 52.0227-2.854 52.0227-6.376 0-3.521-23.2914-6.376-52.0227-6.376-28.7312 0-52.0225 2.855-52.0225 6.376 0 3.522 23.2913 6.376 52.0225 6.376Z"
    />
    <path
      fill="#DDDBF9"
      d="M77.4439 16.2255C71.8647 8.3548 64.0937 1.6796 58.6141 3.9711c-4.6826 1.893-6.6752 7.8707-5.1807 19.2284"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M77.4439 16.2255C71.8647 8.3548 64.0937 1.6796 58.6141 3.9711c-4.6826 1.893-6.6752 7.8707-5.1807 19.2284"
    />
    <path
      fill="#DDDBF9"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M46.1607 26.8859c54.1983-30.7854 83.6883 7.6714 83.6883 39.9512S113.51 127.312 62.998 127.312c-22.5162 0-42.7409-10.561-53.2019-24.11C-3.8531 85.5674.431 52.8891 46.1607 26.8859Z"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M87.9049 16.126c-6.2766 6.5755-10.5607 18.1325-10.5607 31.3831 0 20.4239 10.0625 37.062 22.5162 37.062 12.4536 0 22.5166-16.5384 22.5166-37.062 0-2.7896-.2-6.4759-.499-9.5644-6.974-11.4573-18.331-20.3243-33.9731-21.8187Z"
    />
    <path
      fill="#FBC7BF"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M103.846 33.4614c3.786.9963 7.97-1.1956 11.058-4.4833-5.081-5.1807-11.357-9.1659-19.0287-11.3577-.3985 6.2766 2.3911 14.3465 7.9707 15.841Z"
    />
    <path
      fill="#000"
      d="M94.4804 44.6195c0 3.7859.5978 7.1733-3.1881 7.1733s-8.9666-3.0885-8.9666-6.7747c0-3.6863 5.2803-6.7748 8.9666-6.7748 3.6863 0 3.1881 2.5903 3.1881 6.3762Z"
    />
    <path
      fill="#FBC7BF"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M102.95 67.3352c-5.5796-3.3874-14.8451 1.6937-18.3321 7.2729 3.9852 6.0774 9.3651 9.8633 15.2433 9.8633 2.3908 0 4.7818-.5978 6.9738-1.7933 1.395-5.6789.498-12.6529-3.885-15.3429Z"
    />
    <path
      fill="#DDDBF9"
      d="M10.4946 58.468c-.6974-40.549 20.7229-47.9215 28.195-40.9475 5.2804 4.8818 4.3837 15.9406.0997 23.911 0 0 24.1102-1.7934 26.1028 12.4536 1.1955 8.7673-7.6715 14.4462-12.8522 16.0402"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M10.4946 58.468c-.6974-40.549 20.7229-47.9215 28.195-40.9475 5.2804 4.8818 4.3837 15.9406.0997 23.911 0 0 24.1102-1.7934 26.1028 12.4536 1.1955 8.7673-7.6715 14.4462-12.8522 16.0402"
    />
    <path
      fill="#DDDBF9"
      d="M63.2967 81.3827c-5.5792-7.8707-12.2543-14.3466-16.9369-11.6566-4.7822 2.69-4.5829 12.354-1.0959 23.3132"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M63.2967 81.3827c-5.5792-7.8707-12.2543-14.3466-16.9369-11.6566-4.7822 2.69-4.5829 12.354-1.0959 23.3132"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M105.241 42.5274c1.993-.2989 3.687-1.7933 3.886-3.8855.299-2.4907-1.494-4.6826-3.886-4.9815l-1.295-.1992M102.751 57.6709c1.893.5978 3.288 2.3911 3.188 4.5829-.1 2.4908-2.292 4.3837-4.683 4.1844l-1.2948-.0996"
    />
  </svg>
);
