import * as React from 'react';
export const FyiIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={149}
    height={191}
    fill="none"
    viewBox="0 0 149 191"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path stroke="#000" strokeWidth={3} d="M116.747 60.325v123.178" />
    <path
      fill="#fff"
      d="M103.571 126.931c10.63-.497 20.365 6.556 18.775 12.516-1.391 5.563-9.934 8.444-22.152.596"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M103.571 126.931c10.63-.497 20.365 6.556 18.775 12.516-1.391 5.563-9.934 8.444-22.152.596"
    />
    <path
      fill="#fff"
      d="M40.7895 77.7583c.7947-1.9868 2.4835-4.0729 1.6887-6.3576-1.6887-5.0663-10.3311-11.6226-14.5033-9.9338-4.1722 1.6887-5.9602 11.2251-4.6688 16.49.596 2.5828 4.1721 3.8742 6.4569 4.8676"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M40.7895 77.7583c.7947-1.9868 2.4835-4.0729 1.6887-6.3576-1.6887-5.0663-10.3311-11.6226-14.5033-9.9338-4.1722 1.6887-5.9602 11.2251-4.6688 16.49.596 2.5828 4.1721 3.8742 6.4569 4.8676"
    />
    <path
      fill="#fff"
      d="M81.1208 75.4745c-.7947-1.9868-2.4835-4.0728-1.6888-6.3576 1.6888-5.0662 10.3312-11.6225 14.5033-9.9338 4.1722 1.6888 5.9603 11.2252 4.6689 16.4901-.596 2.5828-4.1722 3.8741-6.4569 4.8675"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M81.1208 75.4745c-.7947-1.9868-2.4835-4.0728-1.6888-6.3576 1.6888-5.0662 10.3312-11.6225 14.5033-9.9338 4.1722 1.6888 5.9603 11.2252 4.6689 16.4901-.596 2.5828-4.1722 3.8742-6.4569 4.8675"
    />
    <path
      fill="#000"
      d="M54.8957 189.215c27.3765 0 49.5693-2.847 49.5693-6.358 0-3.511-22.1928-6.358-49.5693-6.358s-49.5696 2.847-49.5696 6.358c0 3.511 22.1931 6.358 49.5696 6.358Z"
    />
    <path
      fill="#000"
      d="M116.241 187.477c16.458 0 29.801-1.557 29.801-3.477s-13.343-3.477-29.801-3.477c-16.4592 0-29.8017 1.557-29.8017 3.477s13.3425 3.477 29.8017 3.477Z"
    />
    <path
      fill="#fff"
      d="M110.823 125.739c-3.179-35.1652-21.7552-57.0195-47.5831-59.1056-39.3377-3.1788-48.8741 33.9736-56.8211 56.4236-9.4371 26.225 5.2649 44.106 23.7417 52.55.6953 4.371 3.0794 7.053 6.2583 7.55 2.4834.397 4.9668-.696 6.8543-3.179 3.9735.894 7.947 1.291 11.7218 1.291 4.1722 0 8.245-.199 12.1192-.795 1.7881 2.087 4.0729 2.981 6.3576 2.683 2.5828-.398 4.5696-2.285 5.6623-5.265 20.96-6.755 34.272-23.444 31.689-52.153Z"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M110.823 125.739c-3.179-35.1652-21.7552-57.0195-47.5831-59.1056-39.3377-3.1788-48.8741 33.9736-56.8211 56.4236-9.4371 26.225 5.2649 44.106 23.7417 52.55.6953 4.371 3.0794 7.053 6.2582 7.55 2.4835.397 4.9669-.696 6.8544-3.179 3.9735.894 7.947 1.291 11.7218 1.291 4.1722 0 8.245-.199 12.1192-.795 1.7881 2.087 4.0729 2.981 6.3576 2.683 2.5828-.398 4.5696-2.285 5.6623-5.265 20.96-6.755 34.271-23.444 31.689-52.153Z"
    />
    <path
      fill="#000"
      d="M90.1605 90.1748c1.2618 0 2.2848-1.0229 2.2848-2.2848 0-1.2618-1.023-2.2847-2.2848-2.2847-1.2618 0-2.2848 1.0229-2.2848 2.2847 0 1.2619 1.023 2.2848 2.2848 2.2848ZM46.4518 90.1748c1.2619 0 2.2848-1.0229 2.2848-2.2848 0-1.2618-1.0229-2.2847-2.2848-2.2847-1.2618 0-2.2847 1.0229-2.2847 2.2847 0 1.2619 1.0229 2.2848 2.2847 2.2848Z"
    />
    <path
      fill="#fff"
      d="M29.763 129.117c.9934 10.629-5.5629 20.662-11.6225 19.371-5.5629-1.192-8.9404-9.537-1.6887-22.053"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M29.763 129.117c.9934 10.629-5.5629 20.662-11.6225 19.371-5.5629-1.192-8.9404-9.537-1.6887-22.053"
    />
    <path
      fill="#000"
      d="M73.5711 101.799c2.7815 0 5.0663 0 5.0663-2.2852 0-2.1855-2.2848-2.2848-5.0663-2.2848h-8.4437c-2.7814 0-5.0662 0-5.0662 2.2848 0 2.2852 2.2848 2.2852 5.0662 2.2852h8.4437Z"
    />
    <path
      fill="#F9AA9E"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.352}
      d="M43.8212 98.19c.5529-2.1803-1.5424-4.5928-4.6799-5.3884-3.1375-.7957-6.1292.3268-6.6821 2.5072-.5529 2.1803 1.5423 4.5927 4.6799 5.3882 3.1375.796 6.1292-.327 6.6821-2.507ZM99.7691 101.103c2.9129-1.4122 4.4779-4.1978 3.4969-6.2218-.982-2.024-4.1383-2.5199-7.0509-1.1076-2.9125 1.4122-4.4781 4.1979-3.4966 6.2218.9814 2.0236 4.138 2.5196 7.0506 1.1076Z"
    />
    <circle
      cx={117.234}
      cy={31.517}
      r={28.301}
      fill="#AC9EF2"
      stroke="#1D1D1D"
      strokeWidth={3}
    />
    <rect
      width={20.024}
      height={7}
      x={113.706}
      y={48.602}
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeWidth={3}
      rx={3.467}
      transform="rotate(-90.507 113.706 48.602)"
    />
    <rect
      width={7}
      height={7}
      x={113.575}
      y={22.575}
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeWidth={3}
      rx={3.467}
      transform="rotate(-90.507 113.575 22.575)"
    />
  </svg>
);
