import * as React from 'react';
export const YouveGotMailIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={100}
    height={100}
    fill="none"
    viewBox="0 0 100 100"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M8.726 55.7613a4 4 0 0 1 1.9277-3.4213l37.2739-22.5772a4 4 0 0 1 4.1447 0L89.3462 52.34a3.9999 3.9999 0 0 1 1.9277 3.4213v37.737c0 2.2092-1.7909 4.0001-4 4.0001H12.7261c-2.2092 0-4-1.7909-4-4V55.7613Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M8.726 55.7613a4 4 0 0 1 1.9277-3.4213l37.2739-22.5772a4 4 0 0 1 4.1447 0L89.3462 52.34a3.9999 3.9999 0 0 1 1.9277 3.4213v37.737c0 2.2092-1.7909 4.0001-4 4.0001H12.7261c-2.2092 0-4-1.7909-4-4V55.7613Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M8.726 55.7613a4 4 0 0 1 1.9277-3.4213l37.2739-22.5772a4 4 0 0 1 4.1447 0L89.3462 52.34a3.9999 3.9999 0 0 1 1.9277 3.4213v37.737c0 2.2092-1.7909 4.0001-4 4.0001H12.7261c-2.2092 0-4-1.7909-4-4V55.7613Z"
    />
    <rect
      width={55.561}
      height={59.059}
      x={22.22}
      y={22.75}
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      rx={4}
    />
    <path
      fill="#fff"
      d="m49.8677 80.4861 36.6438-17.9912-1.5875 27.2513H14.0176V62.4949l35.8501 17.9912Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="m49.8677 80.4861 36.6438-17.9912-1.5875 27.2513H14.0176V62.4949l35.8501 17.9912Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M8.726 59.5845 50 80.6183l41.2739-21.0338M82.75 97.625 49.2812 80.25"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M59.6165 40.5075 46.1411 57.1747a.4286.4286 0 0 1-.6286.0411l-7.1192-6.7688M71.3395 7.9414 65.8851 13.75M48.9235 2v8.7421M28.0878 7.9414l5.4543 5.8085"
    />
  </svg>
);
