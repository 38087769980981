import * as React from 'react';
export const CompanyIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={37}
    height={39}
    fill="none"
    viewBox="0 0 37 39"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M26.1627 2H4.1c-.8284 0-1.5.6716-1.5 1.5v31c0 .8284.6716 1.5 1.5 1.5h22.0627c.8284 0 1.5-.6716 1.5-1.5v-31c0-.8284-.6716-1.5-1.5-1.5Z"
    />
    <path
      fill="#5A3DE6"
      fillOpacity={0.4}
      d="M26.1627 2H4.1c-.8284 0-1.5.6716-1.5 1.5v31c0 .8284.6716 1.5 1.5 1.5h22.0627c.8284 0 1.5-.6716 1.5-1.5v-31c0-.8284-.6716-1.5-1.5-1.5Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M26.1627 2H4.1c-.8284 0-1.5.6716-1.5 1.5v31c0 .8284.6716 1.5 1.5 1.5h22.0627c.8284 0 1.5-.6716 1.5-1.5v-31c0-.8284-.6716-1.5-1.5-1.5Z"
    />
    <path
      fill="#fff"
      d="M33.1 15H21.0624c-.8284 0-1.5.6716-1.5 1.5v19c0 .8284.6716 1.5 1.5 1.5H33.1c.8285 0 1.5-.6716 1.5-1.5v-19c0-.8284-.6715-1.5-1.5-1.5Z"
    />
    <path
      fill="#5A3DE6"
      fillOpacity={0.25}
      d="M33.1 15H21.0624c-.8284 0-1.5.6716-1.5 1.5v19c0 .8284.6716 1.5 1.5 1.5H33.1c.8285 0 1.5-.6716 1.5-1.5v-19c0-.8284-.6715-1.5-1.5-1.5Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M33.1 15H21.0624c-.8284 0-1.5.6716-1.5 1.5v19c0 .8284.6716 1.5 1.5 1.5H33.1c.8285 0 1.5-.6716 1.5-1.5v-19c0-.8284-.6715-1.5-1.5-1.5Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12 10.5H8V7h4v3.5Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      fillRule="evenodd"
      d="M12 10.5H8V7h4v3.5Z"
      clipRule="evenodd"
    />
    <path
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 10.5H8V7h4v3.5Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M29 23.5h-4V20h4v3.5Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      fillRule="evenodd"
      d="M29 23.5h-4V20h4v3.5Z"
      clipRule="evenodd"
    />
    <path
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M29 23.5h-4V20h4v3.5Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M29 30.5h-4V27h4v3.5Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      fillRule="evenodd"
      d="M29 30.5h-4V27h4v3.5Z"
      clipRule="evenodd"
    />
    <path
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M29 30.5h-4V27h4v3.5Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12 18.5H8V15h4v3.5Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      fillRule="evenodd"
      d="M12 18.5H8V15h4v3.5Z"
      clipRule="evenodd"
    />
    <path
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 18.5H8V15h4v3.5Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12 26.5H8V23h4v3.5Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      fillRule="evenodd"
      d="M12 26.5H8V23h4v3.5Z"
      clipRule="evenodd"
    />
    <path
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 26.5H8V23h4v3.5Z"
      clipRule="evenodd"
    />
  </svg>
);
