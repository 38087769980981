import * as React from 'react';
export const MoneyToWhereIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={66}
    height={78}
    fill="none"
    viewBox="0 0 66 78"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M33.1216 31.2717V11.5754"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M33.1216 3v11.9921h18.0916l7.3147-6.242L51.2132 3H33.1216ZM33.1191 10.9129V22.905H20.416l-7.3147-6.2419 7.3147-5.7502h12.7031Z"
    />
    <path
      fill="#FED491"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M63.2394 54.0923H3v20.54h60.2394v-20.54Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M3 64.1111h60.2394"
    />
    <path
      fill="#FED491"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M48.1778 33.6982H18.058v20.3864h30.1198V33.6982Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M18.058 44.0435h30.1198M33.1216 54.0923v20.54"
    />
  </svg>
);
