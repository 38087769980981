import * as React from 'react';
export const NewCompanyIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={126}
    height={65}
    fill="none"
    viewBox="0 0 126 65"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M112.358 32.161H123.4M2.2 32.161H13.242M107.324 8.048 116.928 2.6M17.9887 8.048 8.3843 2.6M106.551 55.9358l9.604 5.4478M19.5344 56.7087 9.93 62.1566"
    />
    <path
      fill="#fff"
      d="M75.8909 3.8H41.3091C39.592 3.8 38.2 5.192 38.2 6.9092V57.091c0 1.7171 1.392 3.1091 3.1091 3.1091h34.5818c1.7171 0 3.1091-1.392 3.1091-3.1091V6.9091c0-1.717-1.392-3.109-3.1091-3.109Z"
    />
    <path
      fill="#5A3DE6"
      fillOpacity={0.4}
      d="M75.8909 3.8H41.3091C39.592 3.8 38.2 5.192 38.2 6.9092V57.091c0 1.7171 1.392 3.1091 3.1091 3.1091h34.5818c1.7171 0 3.1091-1.392 3.1091-3.1091V6.9091c0-1.717-1.392-3.109-3.1091-3.109Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M75.8909 3.8H41.3091C39.592 3.8 38.2 5.192 38.2 6.9092V57.091c0 1.7171 1.392 3.1091 3.1091 3.1091h34.5818c1.7171 0 3.1091-1.392 3.1091-3.1091V6.9091c0-1.717-1.392-3.109-3.1091-3.109Z"
    />
    <path
      fill="#fff"
      d="M88.1637 22.6729H69.5091c-1.7171 0-3.1091 1.3919-3.1091 3.109v31.0909c0 1.7172 1.392 3.1091 3.1091 3.1091h18.6546c1.7171 0 3.1091-1.3919 3.1091-3.1091V25.7819c0-1.7171-1.392-3.109-3.1091-3.109Z"
    />
    <path
      fill="#5A3DE6"
      fillOpacity={0.25}
      d="M88.1637 22.6729H69.5091c-1.7171 0-3.1091 1.3919-3.1091 3.109v31.0909c0 1.7172 1.392 3.1091 3.1091 3.1091h18.6546c1.7171 0 3.1091-1.3919 3.1091-3.1091V25.7819c0-1.7171-1.392-3.109-3.1091-3.109Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M88.1637 22.6729H69.5091c-1.7171 0-3.1091 1.3919-3.1091 3.109v31.0909c0 1.7172 1.392 3.1091 3.1091 3.1091h18.6546c1.7171 0 3.1091-1.3919 3.1091-3.1091V25.7819c0-1.7171-1.392-3.109-3.1091-3.109Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M81.9451 37.9592h-6.2182v-5.7h6.2182v5.7Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      fillRule="evenodd"
      d="M81.9451 37.9592h-6.2182v-5.7h6.2182v5.7Z"
      clipRule="evenodd"
    />
    <path
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M81.9451 37.9592h-6.2182v-5.7h6.2182v5.7Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M81.9451 50.3954h-6.2182v-5.7h6.2182v5.7Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      fillRule="evenodd"
      d="M81.9451 50.3954h-6.2182v-5.7h6.2182v5.7Z"
      clipRule="evenodd"
    />
    <path
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M81.9451 50.3954h-6.2182v-5.7h6.2182v5.7Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M53.9637 19.3047h-6.2182v-5.7h6.2182v5.7Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      fillRule="evenodd"
      d="M53.9637 19.3047h-6.2182v-5.7h6.2182v5.7Z"
      clipRule="evenodd"
    />
    <path
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M53.9637 19.3047h-6.2182v-5.7h6.2182v5.7Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M53.9637 31.7409h-6.2182v-5.7h6.2182v5.7Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      fillRule="evenodd"
      d="M53.9637 31.7409h-6.2182v-5.7h6.2182v5.7Z"
      clipRule="evenodd"
    />
    <path
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M53.9637 31.7409h-6.2182v-5.7h6.2182v5.7Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M53.9637 44.1773h-6.2182v-5.7h6.2182v5.7Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      fillRule="evenodd"
      d="M53.9637 44.1773h-6.2182v-5.7h6.2182v5.7Z"
      clipRule="evenodd"
    />
    <path
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M53.9637 44.1773h-6.2182v-5.7h6.2182v5.7Z"
      clipRule="evenodd"
    />
  </svg>
);
