import * as React from 'react';
export const GimmeShelterIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={196}
    height={174}
    fill="none"
    viewBox="0 0 196 174"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M103.486 109.924c10.629-.497 20.364 6.556 18.775 12.516-1.391 5.563-9.934 8.444-22.152.596"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M103.486 109.924c10.629-.497 20.364 6.556 18.775 12.516-1.391 5.563-9.934 8.444-22.152.596M109.248 130.586l31.986-41.523"
    />
    <path
      fill="#1D1D1D"
      d="M153.155 172.209c19.421 0 35.166-2.847 35.166-6.358 0-3.511-15.745-6.358-35.166-6.358s-35.166 2.847-35.166 6.358c0 3.511 15.745 6.358 35.166 6.358Z"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M127.625 156.513c1.888 9.239 10.232 15.298 24.338 12.318 14.106-2.98 17.98-10.728 14.503-19.47-2.582-6.457-7.947-14.603-21.556-13.907-14.404.794-19.172 11.92-17.285 21.059Z"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M136.083 164.377c.125-2.466-1.772-4.566-4.238-4.691s-4.566 1.773-4.691 4.238c-.124 2.466 1.773 4.566 4.239 4.691 2.465.125 4.565-1.773 4.69-4.238ZM159.271 166.506c.125-2.466-1.773-4.566-4.238-4.691-2.466-.125-4.566 1.773-4.691 4.238-.125 2.466 1.773 4.566 4.238 4.691 2.466.125 4.566-1.773 4.691-4.238Z"
    />
    <path
      fill="#fff"
      d="M40.7047 60.7517c.7947-1.9867 2.4835-4.0728 1.6888-6.3576-1.6888-5.0662-10.3312-11.6225-14.5033-9.9338-4.1722 1.6888-5.9603 11.2252-4.6689 16.4901.596 2.5828 4.1722 3.8742 6.4569 4.8676"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M40.7047 60.7517c.7947-1.9867 2.4835-4.0728 1.6888-6.3576-1.6888-5.0662-10.3312-11.6225-14.5033-9.9338-4.1722 1.6888-5.9603 11.2252-4.6689 16.4901.596 2.5828 4.1722 3.8742 6.4569 4.8676"
    />
    <path
      fill="#fff"
      d="M81.0358 58.4669c-.7947-1.9868-2.4834-4.0729-1.6887-6.3576 1.6887-5.0663 10.3311-11.6225 14.5033-9.9338 4.1722 1.6887 5.9603 11.2252 4.6689 16.4901-.5961 2.5827-4.1722 3.8741-6.457 4.8675"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M81.0358 58.4669c-.7947-1.9868-2.4834-4.0729-1.6887-6.3576 1.6887-5.0663 10.3311-11.6225 14.5033-9.9338 4.1722 1.6887 5.9603 11.2252 4.6689 16.4901-.5961 2.5827-4.1722 3.8741-6.457 4.8675"
    />
    <path
      fill="#1D1D1D"
      d="M54.8107 172.209c27.3765 0 49.5693-2.847 49.5693-6.358 0-3.511-22.1928-6.358-49.5693-6.358s-49.5696 2.847-49.5696 6.358c0 3.511 22.1931 6.358 49.5696 6.358Z"
    />
    <path
      fill="#fff"
      d="M110.738 108.732c-3.179-35.1657-21.7552-57.02-47.583-59.1061C23.8173 46.4471 14.2808 83.5994 6.3338 106.05c-9.437 26.225 5.2649 44.106 23.7417 52.549.6954 4.371 3.0795 7.053 6.2583 7.55 2.4835.397 4.9669-.695 6.8543-3.179 3.9735.894 7.947 1.292 11.7219 1.292 4.1722 0 8.245-.199 12.1192-.795 1.7881 2.086 4.0728 2.98 6.3576 2.682 2.5828-.397 4.5695-2.285 5.6623-5.265 20.9599-6.755 34.2719-23.444 31.6889-52.152Z"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M110.738 108.732c-3.179-35.1657-21.7552-57.02-47.583-59.1061C23.8173 46.4471 14.2808 83.5994 6.3338 106.05c-9.437 26.225 5.2649 44.106 23.7417 52.549.6954 4.371 3.0795 7.053 6.2583 7.55 2.4835.397 4.9669-.695 6.8543-3.179 3.9735.894 7.947 1.292 11.7219 1.292 4.1722 0 8.245-.199 12.1192-.795 1.7881 2.086 4.0728 2.98 6.3576 2.682 2.5828-.397 4.5695-2.285 5.6623-5.265 20.9599-6.755 34.2719-23.444 31.6889-52.152Z"
    />
    <path
      fill="#000"
      d="M90.0755 73.169c1.2619 0 2.2848-1.023 2.2848-2.2848 0-1.2619-1.0229-2.2848-2.2848-2.2848-1.2618 0-2.2847 1.0229-2.2847 2.2848 0 1.2618 1.0229 2.2848 2.2847 2.2848ZM46.3669 73.169c1.2619 0 2.2848-1.023 2.2848-2.2848 0-1.2619-1.0229-2.2848-2.2848-2.2848-1.2618 0-2.2847 1.0229-2.2847 2.2848 0 1.2618 1.0229 2.2848 2.2847 2.2848Z"
    />
    <path
      fill="#fff"
      d="M29.6783 112.109c.9933 10.629-5.5629 20.663-11.6226 19.371-5.5629-1.192-8.9404-9.536-1.6887-22.053"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M29.6783 112.109c.9933 10.629-5.5629 20.663-11.6226 19.371-5.5629-1.192-8.9404-9.536-1.6887-22.053"
    />
    <path
      fill="#BCB6F3"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M112.228 52.7054c3.179 1.0927 8.543 2.1854 11.026 6.3576 1.689 2.8808 1.49 7.7483-.397 10.4304 3.874.6954 13.609 2.2848 16.987 6.6557 3.079 4.0728 1.49 9.6357 1.39 12.8145 10.133-9.3377 30.994 1.4901 27.815 10.5299 6.258-1.8875 16.49-.5961 21.954 10.8275 4.867-19.5693-1.093-46.7878-20.762-58.609-19.967-11.9205-45-8.5431-58.013.9934Z"
    />
    <path
      fill="#000"
      d="M73.4861 84.7914c2.7815 0 5.0663 0 5.0663-2.2848 0-2.1854-2.2848-2.2847-5.0663-2.2847h-8.4437c-2.7814 0-5.0662 0-5.0662 2.2847 0 2.2848 2.2848 2.2848 5.0662 2.2848h8.4437ZM46.7644 24.7914c-1.9868 0-1.7881 9.4371-1.7881 9.4371 0 2.0861 0 3.7748 1.7881 3.7748 1.788 0 1.788-1.6887 1.788-3.7748-.0993 0 .0994-9.4371-1.788-9.4371ZM59.9762 1.745c-2.3841 0-2.086 11.6226-2.086 11.6226 0 2.5828 0 4.5695 2.1854 4.5695s2.1854-2.0861 2.1854-4.5695c-.0993 0 .0994-11.6225-2.2848-11.6225ZM71.996 27.7715c-1.8874 0-1.6887 7.351-1.6887 7.351 0 1.9867 0 3.5761 1.6887 3.5761 1.6888 0 1.6888-1.5894 1.6888-3.5761 0 0 .0993-7.351-1.6888-7.351ZM3.3537 71.9768c-1.6887 0-2.086 8.245-2.086 8.245 0 1.7881.894 2.3842 1.9867 2.3842 1.192 0 1.8874-.4967 1.8874-2.3842 0 .0994-.0994-8.245-1.7881-8.245ZM22.9233 19.7252c-2.3841 0-2.1855 9.3377-2.1855 9.3377 0 2.5828.0001 4.6689 2.0861 4.6689 2.0861 0 2.1855-2.0861 2.1855-4.6689 0 .0993.298-9.2384-2.0861-9.3377ZM118.089 23.798c-2.086 0-1.987 10.1324-1.987 10.1324 0 2.1855 0 4.0729 1.887 4.0729 1.888 0 1.888-1.7881 1.888-3.9735 0 0 .298-10.2318-1.788-10.2318Z"
    />
    <path
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M129.513 64.2285c11.622-7.4503 24.139-11.5231 37.748-8.245 7.947 11.6225 9.636 26.2252 6.755 37.053"
    />
    <path
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M146.797 80.1225c5.663-12.0198 10.431-20.4635 20.464-24.139"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M142.029 146.778c-.596 1.292-1.887 2.086-3.377 1.888-1.689-.298-2.881-1.888-2.583-3.577M157.327 147.573c-.298 1.391-1.589 2.384-3.079 2.384-1.689 0-3.179-1.391-3.179-3.179"
    />
    <path
      fill="#F9AA9E"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.352}
      d="M43.7362 81.1824c.5529-2.1803-1.5424-4.5927-4.6799-5.3884-3.1375-.7956-6.1292.3269-6.6821 2.5072-.5529 2.1803 1.5423 4.5928 4.6799 5.3884 3.1375.7957 6.1292-.3268 6.6821-2.5072ZM99.6841 84.096c2.9129-1.4122 4.4779-4.1978 3.4969-6.2218-.982-2.024-4.1383-2.5199-7.0508-1.1077-2.9126 1.4123-4.4781 4.1979-3.4967 6.2219.9814 2.024 4.1381 2.5199 7.0506 1.1076Z"
    />
  </svg>
);
