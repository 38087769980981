import * as React from 'react';
export const LonelyLightningIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={118}
    height={185}
    fill="none"
    viewBox="0 0 118 185"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M42.456 67.7191c.7956-1.9889 2.4861-4.0772 1.6906-6.3645-1.6906-5.0717-10.3423-11.6351-14.519-9.9445-4.1767 1.6906-5.9667 11.2373-4.674 16.5079.5967 2.5856 4.1767 3.8784 6.464 4.8728"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M42.456 67.7191c.7956-1.9889 2.4861-4.0772 1.6906-6.3645-1.6906-5.0717-10.3423-11.6351-14.519-9.9445-4.1767 1.6906-5.9667 11.2373-4.674 16.5079.5967 2.5856 4.1767 3.8784 6.464 4.8728"
    />
    <path
      fill="#fff"
      d="M82.8308 65.4332c-.7956-1.9889-2.4861-4.0773-1.6906-6.3645 1.6906-5.0717 10.3423-11.6351 14.519-9.9445 4.1767 1.6905 5.9668 11.2373 4.6738 16.5079-.5965 2.5856-4.1765 3.8783-6.4638 4.8728"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M82.8308 65.4332c-.7956-1.9889-2.4861-4.0773-1.6906-6.3645 1.6906-5.0717 10.3423-11.6351 14.519-9.9445 4.1767 1.6905 5.9668 11.2373 4.6738 16.5079-.5965 2.5856-4.1765 3.8784-6.4638 4.8728"
    />
    <path
      fill="#000"
      d="M56.5773 179.296c27.4061 0 49.6227-2.849 49.6227-6.364 0-3.515-22.2166-6.365-49.6227-6.365-27.4061 0-49.6232 2.85-49.6232 6.365s22.2171 6.364 49.6232 6.364Z"
    />
    <path
      fill="#fff"
      d="M112.565 115.752c-3.182-35.2035-21.7787-57.0814-47.6344-59.1698C25.5502 53.4 16.0035 90.5925 8.0479 113.067c-9.4473 26.254 5.2706 44.154 23.7674 52.607.6961 4.375 3.0828 7.06 6.265 7.558 2.4862.397 4.9723-.697 6.8618-3.183 3.9778.895 7.9556 1.293 11.7345 1.293 4.1767 0 8.254-.199 12.1323-.796 1.79 2.089 4.0773 2.984 6.3645 2.686 2.5856-.398 4.5745-2.288 5.6684-5.271 20.9832-6.762 34.3082-23.469 31.7232-52.209Z"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M39.8593 59.5736c6.8307-3.5319 15.0959-5.3012 25.1921-4.4853 26.8392 2.1677 45.7846 24.8888 49.0066 60.5297 1.316 14.628-1.407 26.345-7.262 35.301-5.732 8.769-14.3733 14.75-24.8213 18.248-1.2865 2.981-3.5347 5.079-6.5732 5.547l-.017.002-.0171.002c-2.591.338-5.119-.576-7.1205-2.574-3.7397.523-7.6287.698-11.5702.698-3.5969 0-7.3705-.351-11.1604-1.135-2.0905 2.33-4.8236 3.46-7.67 3.006-3.7925-.594-6.4363-3.635-7.3636-8.005-9.1753-4.333-17.4654-11.002-22.346-19.944-5.0314-9.218-6.3563-20.704-1.5007-34.2.5735-1.621 1.1582-3.328 1.7647-5.099 3.553-10.3735 7.8574-22.9414 15.0814-33.1285 4.2531-5.9976 9.5568-11.2363 16.3772-14.7629ZM11.2664 108.358c-.6153 1.794-1.2132 3.537-1.8052 5.21l-.0026.007c-4.59 12.756-3.2795 23.345 1.3099 31.753 4.6153 8.455 12.6294 14.851 21.67 18.982l.7312.335.1262.793c.6291 3.954 2.6723 5.946 5.016 6.313h.0054c1.8343.294 3.8062-.47 5.4313-2.609l.5828-.766.9398.211c3.8631.869 7.7302 1.256 11.4055 1.256 4.1335 0 8.1283-.197 11.9044-.778l.8237-.127.5425.633c1.4952 1.745 3.2925 2.395 5.0153 2.176 1.8959-.298 3.5132-1.688 4.4717-4.302l.2518-.686.6959-.225c10.2331-3.298 18.4972-8.983 23.905-17.256 5.407-8.273 8.054-19.279 6.785-33.391-3.143-34.7664-21.3897-55.8012-46.262-57.8101h-.0001c-9.594-.7753-17.2898.9111-23.5734 4.1601-6.2939 3.2543-11.2579 8.1218-15.3085 13.8339-6.9498 9.8004-11.0967 21.8921-14.6616 32.2871Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      d="M91.8802 80.1494c1.2632 0 2.2872-1.024 2.2872-2.2872 0-1.2632-1.024-2.2873-2.2872-2.2873-1.2632 0-2.2873 1.0241-2.2873 2.2873s1.0241 2.2872 2.2873 2.2872ZM48.1243 80.1494c1.2632 0 2.2872-1.024 2.2872-2.2872 0-1.2632-1.024-2.2873-2.2872-2.2873-1.2632 0-2.2873 1.0241-2.2873 2.2873s1.0241 2.2872 2.2873 2.2872Z"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M30.588 127.546c7.849 5.901 14.8942 3.572 16.0693-.057 1.2015-3.726-3.2266-8.278-9.3546-10.029M100.38 127.682c-7.8904 5.842-14.9167 3.462-16.065-.175-1.1739-3.733 3.2865-8.252 9.4257-9.958"
    />
    <path
      fill="#000"
      d="M75.2729 91.7857c2.7845 0 5.0718 0 5.0718-2.2872 0-2.1878-2.2873-2.2873-5.0718-2.2873h-8.4528c-2.7845 0-5.0717 0-5.0717 2.2873 0 2.2872 2.2872 2.2872 5.0717 2.2872h8.4528Z"
    />
    <path
      fill="#F9AA9E"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.352}
      d="M45.4908 88.1732c.5535-2.1826-1.5441-4.5977-4.685-5.3942-3.1409-.7965-6.1358.3272-6.6893 2.5099-.5535 2.1827 1.544 4.5978 4.6849 5.3943 3.141.7964 6.1359-.3273 6.6894-2.51ZM101.499 91.0892c2.916-1.4138 4.483-4.2025 3.501-6.2286-.983-2.0262-4.143-2.5226-7.0584-1.1089-2.9158 1.4138-4.483 4.2025-3.5005 6.2286.9825 2.0262 4.1426 2.5227 7.0579 1.1089Z"
    />
    <path
      fill="#FFCE74"
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M56.3437 19.8214 59.347 4.8052h12.5135l-6.507 10.0108h7.5081L61.3492 38.842V19.8214h-5.0055Z"
    />
  </svg>
);
