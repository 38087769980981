import * as React from 'react';
export const NoBrainNoPainIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={131}
    height={152}
    fill="none"
    viewBox="0 0 131 152"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M73.2127 87.8197c-3.6928-9.73-7.158-21.9205-8.0605-28.3582l-8.4771 4.7821c.0921 3.3848 1.2232 15.2211 5.4196 26.2778 4.1964 11.0566 14.6215 25.1936 29.0383 44.5146 2.8873 3.869 7.7457 8.192 11.654 6.708 6.634-2.517 2.32-9.162-1.772-14-16.5219-19.535-24.1094-30.1944-27.8023-39.9243Z"
    />
    <path
      fill="#5A3DE6"
      fillOpacity={0.5}
      d="M73.2127 87.8197c-3.6928-9.73-7.158-21.9205-8.0605-28.3582l-8.4771 4.7821c.0921 3.3848 1.2232 15.2211 5.4196 26.2778 4.1964 11.0566 14.6215 25.1936 29.0383 44.5146 2.8873 3.869 7.7457 8.192 11.654 6.708 6.634-2.517 2.32-9.162-1.772-14-16.5219-19.535-24.1094-30.1944-27.8023-39.9243Z"
    />
    <path
      stroke="#000"
      strokeWidth={3}
      d="M73.2127 87.8197c-3.6928-9.73-7.158-21.9205-8.0605-28.3582l-8.4771 4.7821c.0921 3.3848 1.2232 15.2211 5.4196 26.2778 4.1964 11.0566 14.6215 25.1936 29.0383 44.5146 2.8873 3.869 7.7457 8.192 11.654 6.708 6.634-2.517 2.32-9.162-1.772-14-16.5219-19.535-24.1094-30.1944-27.8023-39.9243Z"
    />
    <path
      fill="#fff"
      d="M39.213 96.2483c-1.838-7.7438-1.7848-20.1015-3.5083-24.6421l-11.4393 4.3416c1.9387 5.1082 3.3958 16.7142 4.1383 22.9602.8288 6.972 6.1133 22.98 14.8399 32.176 10.0747 10.618 25.3675 17.214 32.0661 18.177 5.9974.862 8.9546-1.443 9.6295-3.517.6749-2.073-1.8538-6.724-7.6392-7.531-3.7981-.529-20.0048-7.032-27.0059-15.669-6.3846-7.877-9.6791-20.389-11.0811-26.2957Z"
    />
    <path
      fill="#5A3DE6"
      fillOpacity={0.5}
      d="M39.213 96.2483c-1.838-7.7438-1.7848-20.1015-3.5083-24.6421l-11.4393 4.3416c1.9387 5.1082 3.3958 16.7142 4.1383 22.9602.8288 6.972 6.1133 22.98 14.8399 32.176 10.0747 10.618 25.3675 17.214 32.0661 18.177 5.9974.862 8.9546-1.443 9.6295-3.517.6749-2.073-1.8538-6.724-7.6392-7.531-3.7981-.529-20.0048-7.032-27.0059-15.669-6.3846-7.877-9.6791-20.389-11.0811-26.2957Z"
    />
    <path
      stroke="#000"
      strokeWidth={3}
      d="M39.213 96.2483c-1.838-7.7438-1.7848-20.1015-3.5083-24.6421l-11.4393 4.3416c1.9387 5.1082 3.3958 16.7142 4.1383 22.9602.8288 6.972 6.1133 22.98 14.8399 32.176 10.0747 10.618 25.3675 17.214 32.0661 18.177 5.9974.862 8.9546-1.443 9.6295-3.517.6749-2.073-1.8538-6.724-7.6392-7.531-3.7981-.529-20.0048-7.032-27.0059-15.669-6.3846-7.877-9.6791-20.389-11.0811-26.2957Z"
    />
    <path
      fill="#fff"
      d="M56.5862 91.1414c-1.455-6.3752-1.3845-15.1887-1.3906-21.6894l-9.0555 3.5673c-1.9612 2.4482-1.412 14.3767-.0125 20.509 1.736 7.6067 4.1159 19.4707 11.4907 29.7837 8.5142 11.905 22.2738 20.789 28.7756 22.666 5.8212 1.681 9.0868 1.547 10.4998-2.396 1.1643-3.249-.6502-7.597-6.8767-9.367-8.4669-2.406-20.2643-11.605-24.2376-18.39-3.9733-6.785-7.4897-17.2197-9.1932-24.6836Z"
    />
    <path
      fill="#5A3DE6"
      fillOpacity={0.5}
      d="M56.5862 91.1414c-1.455-6.3752-1.3845-15.1887-1.3906-21.6894l-9.0555 3.5673c-1.9612 2.4482-1.412 14.3767-.0125 20.509 1.736 7.6067 4.1159 19.4707 11.4907 29.7837 8.5142 11.905 22.2738 20.789 28.7756 22.666 5.8212 1.681 9.0868 1.547 10.4998-2.396 1.1643-3.249-.6502-7.597-6.8767-9.367-8.4669-2.406-20.2643-11.605-24.2376-18.39-3.9733-6.785-7.4897-17.2197-9.1932-24.6836Z"
    />
    <path
      stroke="#000"
      strokeWidth={3}
      d="M56.5862 91.1414c-1.455-6.3752-1.3845-15.1887-1.3906-21.6894l-9.0555 3.5673c-1.9612 2.4482-1.412 14.3767-.0125 20.509 1.736 7.6067 4.1159 19.4707 11.4907 29.7837 8.5142 11.905 22.2738 20.789 28.7756 22.666 5.8212 1.681 9.0868 1.547 10.4998-2.396 1.1643-3.249-.6502-7.597-6.8767-9.367-8.4669-2.406-20.2643-11.605-24.2376-18.39-3.9733-6.785-7.4897-17.2197-9.1932-24.6836Z"
    />
    <path
      fill="#fff"
      d="M83.8589 67.0391c-3.0752-5.7708-10.7561-9.4631-14.2121-10.5879l-5.7953 9.3509c2.3067 1.1329 7.8944 4.6332 11.7913 9.5707 4.871 6.1719 7.7533 21.6641 14.8398 32.1762 7.897 11.715 19.2074 18.382 25.9064 19.345 5.997.862 11.231.223 11.521-4.298.364-5.705-6.401-6.363-9.531-6.75-7.553-.934-13.654-7.138-18.5249-13.31-4.871-6.1717-12.1511-28.2833-15.9952-35.4969Z"
    />
    <path
      fill="#5A3DE6"
      fillOpacity={0.5}
      d="M83.8589 67.0391c-3.0752-5.7708-10.7561-9.4631-14.2121-10.5879l-5.7953 9.3509c2.3067 1.1329 7.8944 4.6332 11.7913 9.5707 4.871 6.1719 7.7533 21.6641 14.8398 32.1762 7.897 11.715 19.2074 18.382 25.9064 19.345 5.997.862 11.231.223 11.521-4.298.364-5.705-6.401-6.363-9.531-6.75-7.553-.934-13.654-7.138-18.5249-13.31-4.871-6.1717-12.1511-28.2833-15.9952-35.4969Z"
    />
    <path
      stroke="#000"
      strokeWidth={3}
      d="M83.8589 67.0391c-3.0752-5.7708-10.7561-9.4631-14.2121-10.5879l-5.7953 9.3509c2.3067 1.1329 7.8944 4.6332 11.7913 9.5707 4.871 6.1719 7.7533 21.6641 14.8398 32.1762 7.897 11.715 19.2074 18.382 25.9064 19.345 5.997.862 11.231.223 11.521-4.298.364-5.705-6.401-6.363-9.531-6.75-7.553-.934-13.654-7.138-18.5249-13.31-4.871-6.1717-12.1511-28.2833-15.9952-35.4969Z"
    />
    <path
      fill="#fff"
      d="M21.3909 83.0937c-2.6768-.9245-.9296-5.388.2786-7.5041l66.5303-14.0323c4.1905 2.2183 4.4049 6.7644 1.754 8.3162-3.3135 1.9398-10.0054-.3714-12.3815.1297-2.3761.5012-1.4991 4.6593-4.4692 5.2858-2.9701.6264-6.8172-2.9053-9.7873-2.2789-2.9701.6265-2.0931 4.7846-6.2512 5.6616-4.1581.8771-6.8172-2.9052-10.3813-2.1535-3.5641.7517-2.5618 5.5039-6.1259 6.2556-3.5642.7518-6.9425-3.4992-10.5067-2.7475-3.5641.7517-5.3138 4.223-8.6598 3.0674Z"
    />
    <path
      fill="#5A3DE6"
      fillOpacity={0.2}
      d="M21.3909 83.0937c-2.6768-.9245-.9296-5.388.2786-7.5041l66.5303-14.0323c4.1905 2.2183 4.4049 6.7644 1.754 8.3162-3.3135 1.9398-10.0054-.3714-12.3815.1297-2.3761.5012-1.4991 4.6593-4.4692 5.2858-2.9701.6264-6.8172-2.9053-9.7873-2.2789-2.9701.6265-2.0931 4.7846-6.2512 5.6616-4.1581.8771-6.8172-2.9052-10.3813-2.1535-3.5641.7517-2.5618 5.5039-6.1259 6.2556-3.5642.7518-6.9425-3.4992-10.5067-2.7475-3.5641.7517-5.3138 4.223-8.6598 3.0674Z"
    />
    <path
      stroke="#000"
      strokeWidth={3}
      d="M21.3909 83.0937c-2.6768-.9245-.9296-5.388.2786-7.5041l66.5303-14.0323c4.1905 2.2183 4.4049 6.7644 1.754 8.3162-3.3135 1.9398-10.0054-.3714-12.3815.1297-2.3761.5012-1.4991 4.6593-4.4692 5.2858-2.9701.6264-6.8172-2.9053-9.7873-2.2789-2.9701.6265-2.0931 4.7846-6.2512 5.6616-4.1581.8771-6.8172-2.9052-10.3813-2.1535-3.5641.7517-2.5618 5.5039-6.1259 6.2556-3.5642.7518-6.9425-3.4992-10.5067-2.7475-3.5641.7517-5.3138 4.223-8.6598 3.0674Z"
    />
    <path
      fill="#fff"
      d="M3.7796 61.3701c3.007 14.2564 12.7851 14.6759 16.9527 15.6582 4.1676.9823 65.0614-10.9926 68.5625-13.2201 4.3763-2.7843 11.9732-5.0071 8.9339-22.3589-3.0393-17.3519-20.1214-45.3917-56.7-36.4358S.021 43.5495 3.7797 61.3701Z"
    />
    <path
      fill="#5A3DE6"
      fillOpacity={0.2}
      d="M3.7796 61.3701c3.007 14.2564 12.7851 14.6759 16.9527 15.6582 4.1676.9823 65.0614-10.9926 68.5625-13.2201 4.3763-2.7843 11.9732-5.0071 8.9339-22.3589-3.0393-17.3519-20.1214-45.3917-56.7-36.4358S.021 43.5495 3.7797 61.3701Z"
    />
    <path
      stroke="#000"
      strokeWidth={3}
      d="M3.7796 61.3701c3.007 14.2564 12.7851 14.6759 16.9527 15.6582 4.1676.9823 65.0614-10.9926 68.5625-13.2201 4.3763-2.7843 11.9732-5.0071 8.9339-22.3589-3.0393-17.3519-20.1214-45.3917-56.7-36.4358S.021 43.5495 3.7797 61.3701Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={3}
      d="m35.685 57.046 21.711-3.916"
    />
    <path
      fill="#1D1D1D"
      d="M24.5824 50.4485c.1266-3.7729-1.36-6.8848-3.3204-6.9506-1.9605-.0658-3.6523 2.9395-3.7789 6.7124-.1266 3.7729 1.36 6.8848 3.3205 6.9505 1.9604.0658 3.6523-2.9394 3.7788-6.7123ZM74.1972 41.7807c.1266-3.7729-1.36-6.8847-3.3205-6.9505-1.9604-.0658-3.6523 2.9394-3.7788 6.7124-.1266 3.7729 1.36 6.8847 3.3204 6.9505 1.9605.0658 3.6523-2.9394 3.7789-6.7124Z"
    />
  </svg>
);
