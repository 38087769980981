import * as React from 'react';
export const TransferVismaEAccountingIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={307}
    height={201}
    fill="none"
    viewBox="0 0 307 201"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#3B2D81"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m6.4034 106.994 4.3555 12.397c.4397 1.251 1.6493 2.065 2.9743 2.002L115.5 116.5l-32.9386-13.282a2.9963 2.9963 0 0 0-1.1219-.218H9.2338c-2.068 0-3.5159 2.043-2.8304 3.994Z"
    />
    <path
      fill="#3B2D81"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m6.4034 106.994 4.3555 12.397c.4397 1.251 1.6493 2.065 2.9743 2.002L115.5 116.5l-32.9386-13.282a2.9963 2.9963 0 0 0-1.1219-.218H9.2338c-2.068 0-3.5159 2.043-2.8304 3.994ZM300.597 106.994l-4.356 12.397a3.0013 3.0013 0 0 1-2.974 2.002L191.5 116.5l32.939-13.282c.356-.144.737-.218 1.122-.218h72.205c2.068 0 3.516 2.043 2.831 3.994Z"
    />
    <path
      fill="#3B2D81"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m300.597 106.994-4.356 12.397a3.0013 3.0013 0 0 1-2.974 2.002L191.5 116.5l32.939-13.282c.356-.144.737-.218 1.122-.218h72.205c2.068 0 3.516 2.043 2.831 3.994Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M135.893 16H92.107C90.9433 16 90 16.9433 90 18.107v55.786c0 1.1637.9433 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V18.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M126.5 27.5h-25M126.5 36.5h-25M126.5 45.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M135.893 16H92.107C90.9433 16 90 16.9433 90 18.107v55.786c0 1.1637.9433 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V18.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M126.5 27.5h-25M126.5 36.5h-25M126.5 45.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M146.893 5h-43.786C101.943 5 101 5.9433 101 7.107v55.786c0 1.1637.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V7.107C149 5.9433 148.057 5 146.893 5Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M137.5 16.5h-25M137.5 25.5h-25M137.5 34.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M146.893 5h-43.786C101.943 5 101 5.9433 101 7.107v55.786c0 1.1637.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V7.107C149 5.9433 148.057 5 146.893 5Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M137.5 16.5h-25M137.5 25.5h-25M137.5 34.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M247.893 24h-43.786c-1.164 0-2.107.9433-2.107 2.107v55.786c0 1.1637.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V26.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M238.5 35.5h-25M238.5 44.5h-25M238.5 53.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M247.893 24h-43.786c-1.164 0-2.107.9433-2.107 2.107v55.786c0 1.1637.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V26.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M238.5 35.5h-25M238.5 44.5h-25M238.5 53.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M86.893 96H43.107C41.9433 96 41 96.9433 41 98.107v55.786c0 1.164.9433 2.107 2.107 2.107h43.786c1.1637 0 2.107-.943 2.107-2.107V98.107C89 96.9433 88.0567 96 86.893 96Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M77.5 107.5h-25M77.5 116.5h-25M77.5 125.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M86.893 96H43.107C41.9433 96 41 96.9433 41 98.107v55.786c0 1.164.9433 2.107 2.107 2.107h43.786c1.1637 0 2.107-.943 2.107-2.107V98.107C89 96.9433 88.0567 96 86.893 96Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M77.5 107.5h-25M77.5 116.5h-25M77.5 125.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M268.893 73h-43.786c-1.164 0-2.107.9433-2.107 2.107v55.786c0 1.164.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.943 2.107-2.107V75.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M259.5 84.5h-25M259.5 93.5h-25M259.5 102.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M268.893 73h-43.786c-1.164 0-2.107.9433-2.107 2.107v55.786c0 1.164.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.943 2.107-2.107V75.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M259.5 84.5h-25M259.5 93.5h-25M259.5 102.5h-25"
    />
    <path
      fill="#DED8FA"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m8.407 103.373 15.7037 15.547A3.001 3.001 0 0 1 25 121.052v68.092c0 2.755-3.4004 4.053-5.2362 2L5.7637 175.487A2.9998 2.9998 0 0 1 5 173.488v-68.694c0-1.777 2.1443-2.671 3.407-1.421Z"
    />
    <path
      fill="#DED8FA"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m8.407 103.373 15.7037 15.547A3.001 3.001 0 0 1 25 121.052v68.092c0 2.755-3.4004 4.053-5.2362 2L5.7637 175.487A2.9998 2.9998 0 0 1 5 173.488v-68.694c0-1.777 2.1443-2.671 3.407-1.421ZM298.593 103.373l-15.704 15.547a3.001 3.001 0 0 0-.889 2.132v68.092c0 2.755 3.4 4.053 5.236 2l14-15.657c.492-.55.764-1.262.764-1.999v-68.694c0-1.777-2.144-2.671-3.407-1.421Z"
    />
    <path
      fill="#DED8FA"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m298.593 103.373-15.704 15.547a3.001 3.001 0 0 0-.889 2.132v68.092c0 2.755 3.4 4.053 5.236 2l14-15.657c.492-.55.764-1.262.764-1.999v-68.694c0-1.777-2.144-2.671-3.407-1.421Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M113.893 116H22.7192c-1.1637 0-2.107.943-2.107 2.107v71.786c0 1.164.9433 2.107 2.107 2.107h91.1738c1.164 0 2.107-.943 2.107-2.107v-71.786c0-1.164-.943-2.107-2.107-2.107Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M113.893 116H22.7192c-1.1637 0-2.107.943-2.107 2.107v71.786c0 1.164.9433 2.107 2.107 2.107h91.1738c1.164 0 2.107-.943 2.107-2.107v-71.786c0-1.164-.943-2.107-2.107-2.107ZM193.107 116h91.174c1.163 0 2.107.943 2.107 2.107v71.786c0 1.164-.944 2.107-2.107 2.107h-91.174c-1.164 0-2.107-.943-2.107-2.107v-71.786c0-1.164.943-2.107 2.107-2.107Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M193.107 116h91.174c1.163 0 2.107.943 2.107 2.107v71.786c0 1.164-.944 2.107-2.107 2.107h-91.174c-1.164 0-2.107-.943-2.107-2.107v-71.786c0-1.164.943-2.107 2.107-2.107Z"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M187.893 9h-19.786c-1.164 0-2.107.9433-2.107 2.107v19.786c0 1.1637.943 2.107 2.107 2.107h19.786c1.164 0 2.107-.9433 2.107-2.107V11.107C190 9.9433 189.057 9 187.893 9Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M173 20.6267 177.125 25 184 17"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M187.893 9h-19.786c-1.164 0-2.107.9433-2.107 2.107v19.786c0 1.1637.943 2.107 2.107 2.107h19.786c1.164 0 2.107-.9433 2.107-2.107V11.107C190 9.9433 189.057 9 187.893 9Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M173 20.6267 177.125 25 184 17"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M77.893 44H58.107C56.9433 44 56 44.9433 56 46.107v19.786c0 1.1637.9433 2.107 2.107 2.107h19.786C79.0567 68 80 67.0567 80 65.893V46.107C80 44.9433 79.0567 44 77.893 44Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M63 55.6267 67.125 60 74 52"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M77.893 44H58.107C56.9433 44 56 44.9433 56 46.107v19.786c0 1.1637.9433 2.107 2.107 2.107h19.786C79.0567 68 80 67.0567 80 65.893V46.107C80 44.9433 79.0567 44 77.893 44Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M63 55.6267 67.125 60 74 52"
    />
    <path
      fill="#fff"
      d="M76.1202 144.942v26.827H56v-31.298c0-1.186.4711-2.323 1.3096-3.161a4.4694 4.4694 0 0 1 3.1616-1.31"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.236}
      d="M76.1202 144.942v26.827H56v-31.298c0-1.186.4711-2.323 1.3096-3.161a4.4694 4.4694 0 0 1 3.1616-1.31"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.236}
      d="M56 156.12c0-1.186.4711-2.323 1.3096-3.162a4.4726 4.4726 0 0 1 3.1616-1.309"
    />
    <path
      fill="#BDB2F5"
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.236}
      d="M85.0625 144.942H64.9423v-4.471c0-1.186-.4711-2.323-1.3096-3.161a4.4694 4.4694 0 0 0-3.1616-1.31h20.1202c1.1858 0 2.3231.471 3.1616 1.31a4.4687 4.4687 0 0 1 1.3096 3.161v4.471Z"
    />
    <path
      fill="#FDC7BD"
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.236}
      d="M85.0625 160.591H64.9423v-4.471c0-1.186-.4711-2.323-1.3096-3.162a4.4726 4.4726 0 0 0-3.1616-1.309h20.1202c1.1858 0 2.3231.471 3.1616 1.309a4.4727 4.4727 0 0 1 1.3096 3.162v4.471Z"
    />
    <path
      fill="#8C77EE"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M165.893 72h-33.786c-1.164 0-2.107.9433-2.107 2.107v19.786c0 1.1637.943 2.107 2.107 2.107h33.786c1.164 0 2.107-.9433 2.107-2.107V74.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      fill="#8C77EE"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M165.893 72h-33.786c-1.164 0-2.107.9433-2.107 2.107v19.786c0 1.1637.943 2.107 2.107 2.107h33.786c1.164 0 2.107-.9433 2.107-2.107V74.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M139 83.5h20M154 78l5.5 5.5L154 89M139 83.5h20"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="m154 78 5.5 5.5L154 89"
    />
    <path
      fill="url(#TransferVismaEAccountingIllustration__a)"
      d="M262.399 145.348v.431l-.002.045-.05 16.593c0 2.445-1.306 4.681-3.396 5.879l-14.832 8.582c-.5.28-1.022.493-1.558.641-1.216.339-2.498.341-3.716 0a7 7 0 0 1-1.568-.643l-14.831-8.53c-2.14-1.198-3.446-3.486-3.446-5.931 0-.103.002-.208.002-.312.009-1.321.084-2.833.352-4.205.164-.844.403-1.635.743-2.297-.025-.256-.05-.471-.07-.653-.16-1.368-.173-.915.748-2.936.367.624.783 1.249 1.254 1.873.263.327.54.642.835.94.872.893 1.87 1.662 2.926 2.285l13.213 7.595a3.309 3.309 0 0 0 3.393 0c.419-.208 7.52-4.318 13.161-7.595 1.462-.832 2.716-1.924 3.761-3.225.011-.022.023-.047.034-.07 1.13-2.429 2.669-4.86 2.95-7.592v-.007c.025-.249.081-.52.095-.778.002-.029.002-.061.002-.092v.002Z"
    />
    <path
      fill="#1D1D1D"
      d="M222.916 154.771c-.018.018-.036.036-.052.056-1.961 2.028-3.553 4.91-3.864 7.59 0-.103.002-.208.002-.311.018-1.124.129-2.703.352-4.206.162-1.092.382-2.144.673-2.95.475-1.332 1.13-1.994 2.002-1.063.313.312.574.624.887.884Z"
      opacity={0.15}
    />
    <path
      fill="url(#TransferVismaEAccountingIllustration__b)"
      d="M262.399 145.306v.042c0 .032 0 .063-.002.092 0 .13-.003.258-.009.386-.007.222-.021.442-.041.662-.236 2.734-1.286 5.298-2.977 7.348l-.018-.018c-.309-.373-.637-.727-.984-1.068a15.0577 15.0577 0 0 0-2.759-2.14c-5.639-3.174-12.794-7.283-13.161-7.489a3.3016 3.3016 0 0 0-3.394 0l-13.212 7.541c-1.462.884-2.768 1.976-3.813 3.225-1.867 2.313-2.955 5.209-3.027 8.219 0 .103-.002.208-.002.311v-17.111c0-2.445 1.306-4.682 3.394-5.879l14.883-8.53c2.142-1.196 4.703-1.196 6.842 0l14.884 8.53c2.142 1.197 3.396 3.485 3.396 5.879Z"
    />
    <path
      fill="#1D1D1D"
      d="M262.399 145.772c0 .018 0 .034-.002.052-.007.294-.023.59-.05.879-.239 2.629-1.295 5.132-2.977 7.133l-.018-.018-1.027-1.023c.014-.016.029-.029.043-.045 1.844-1.895 3.367-4.196 3.934-6.525v-.006c.016-.065.032-.128.045-.193.016-.065.029-.133.041-.2.004-.016.006-.031.009-.047.002-.002.002-.005.002-.007Z"
      opacity={0.15}
    />
    <defs>
      <linearGradient
        id="TransferVismaEAccountingIllustration__a"
        x1={262.496}
        x2={219.007}
        y1={161.561}
        y2={161.561}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EC4352" />
        <stop offset={0.28} stopColor="#E8404F" />
        <stop offset={0.58} stopColor="#DB3745" />
        <stop offset={0.88} stopColor="#C72735" />
        <stop offset={1} stopColor="#BC1F2D" />
      </linearGradient>
      <linearGradient
        id="TransferVismaEAccountingIllustration__b"
        x1={219}
        x2={262.399}
        y1={146.207}
        y2={146.207}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007AC7" />
        <stop offset={0.3} stopColor="#0081C7" />
        <stop offset={0.6} stopColor="#007BC7" />
        <stop offset={0.89} stopColor="#005EC7" />
        <stop offset={1} stopColor="#005AC7" />
      </linearGradient>
    </defs>
  </svg>
);
