import * as React from 'react';
export const TransferPowerOfficeGoIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={307}
    height={201}
    fill="none"
    viewBox="0 0 307 201"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#3B2D81"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m6.4034 106.994 4.3555 12.397c.4397 1.251 1.6493 2.065 2.9743 2.002L115.5 116.5l-32.9386-13.282a2.9963 2.9963 0 0 0-1.1219-.218H9.2338c-2.068 0-3.5159 2.043-2.8304 3.994Z"
    />
    <path
      fill="#3B2D81"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m6.4034 106.994 4.3555 12.397c.4397 1.251 1.6493 2.065 2.9743 2.002L115.5 116.5l-32.9386-13.282a2.9963 2.9963 0 0 0-1.1219-.218H9.2338c-2.068 0-3.5159 2.043-2.8304 3.994ZM300.597 106.994l-4.356 12.397a3.0013 3.0013 0 0 1-2.974 2.002L191.5 116.5l32.939-13.282c.356-.144.737-.218 1.122-.218h72.205c2.068 0 3.516 2.043 2.831 3.994Z"
    />
    <path
      fill="#3B2D81"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m300.597 106.994-4.356 12.397a3.0013 3.0013 0 0 1-2.974 2.002L191.5 116.5l32.939-13.282c.356-.144.737-.218 1.122-.218h72.205c2.068 0 3.516 2.043 2.831 3.994Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M135.893 16H92.107C90.9433 16 90 16.9433 90 18.107v55.786c0 1.1637.9433 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V18.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M126.5 27.5h-25M126.5 36.5h-25M126.5 45.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M135.893 16H92.107C90.9433 16 90 16.9433 90 18.107v55.786c0 1.1637.9433 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V18.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M126.5 27.5h-25M126.5 36.5h-25M126.5 45.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M146.893 5h-43.786C101.943 5 101 5.9433 101 7.107v55.786c0 1.1637.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V7.107C149 5.9433 148.057 5 146.893 5Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M137.5 16.5h-25M137.5 25.5h-25M137.5 34.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M146.893 5h-43.786C101.943 5 101 5.9433 101 7.107v55.786c0 1.1637.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V7.107C149 5.9433 148.057 5 146.893 5Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M137.5 16.5h-25M137.5 25.5h-25M137.5 34.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M247.893 24h-43.786c-1.164 0-2.107.9433-2.107 2.107v55.786c0 1.1637.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V26.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M238.5 35.5h-25M238.5 44.5h-25M238.5 53.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M247.893 24h-43.786c-1.164 0-2.107.9433-2.107 2.107v55.786c0 1.1637.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V26.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M238.5 35.5h-25M238.5 44.5h-25M238.5 53.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M86.893 96H43.107C41.9433 96 41 96.9433 41 98.107v55.786c0 1.164.9433 2.107 2.107 2.107h43.786c1.1637 0 2.107-.943 2.107-2.107V98.107C89 96.9433 88.0567 96 86.893 96Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M77.5 107.5h-25M77.5 116.5h-25M77.5 125.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M86.893 96H43.107C41.9433 96 41 96.9433 41 98.107v55.786c0 1.164.9433 2.107 2.107 2.107h43.786c1.1637 0 2.107-.943 2.107-2.107V98.107C89 96.9433 88.0567 96 86.893 96Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M77.5 107.5h-25M77.5 116.5h-25M77.5 125.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M268.893 73h-43.786c-1.164 0-2.107.9433-2.107 2.107v55.786c0 1.164.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.943 2.107-2.107V75.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M259.5 84.5h-25M259.5 93.5h-25M259.5 102.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M268.893 73h-43.786c-1.164 0-2.107.9433-2.107 2.107v55.786c0 1.164.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.943 2.107-2.107V75.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M259.5 84.5h-25M259.5 93.5h-25M259.5 102.5h-25"
    />
    <path
      fill="#DED8FA"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m8.407 103.373 15.7037 15.547A3.001 3.001 0 0 1 25 121.052v68.092c0 2.755-3.4004 4.053-5.2362 2L5.7637 175.487A2.9998 2.9998 0 0 1 5 173.488v-68.694c0-1.777 2.1443-2.671 3.407-1.421Z"
    />
    <path
      fill="#DED8FA"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m8.407 103.373 15.7037 15.547A3.001 3.001 0 0 1 25 121.052v68.092c0 2.755-3.4004 4.053-5.2362 2L5.7637 175.487A2.9998 2.9998 0 0 1 5 173.488v-68.694c0-1.777 2.1443-2.671 3.407-1.421ZM298.593 103.373l-15.704 15.547a3.001 3.001 0 0 0-.889 2.132v68.092c0 2.755 3.4 4.053 5.236 2l14-15.657c.492-.55.764-1.262.764-1.999v-68.694c0-1.777-2.144-2.671-3.407-1.421Z"
    />
    <path
      fill="#DED8FA"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m298.593 103.373-15.704 15.547a3.001 3.001 0 0 0-.889 2.132v68.092c0 2.755 3.4 4.053 5.236 2l14-15.657c.492-.55.764-1.262.764-1.999v-68.694c0-1.777-2.144-2.671-3.407-1.421Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M113.893 116H22.7192c-1.1637 0-2.107.943-2.107 2.107v71.786c0 1.164.9433 2.107 2.107 2.107h91.1738c1.164 0 2.107-.943 2.107-2.107v-71.786c0-1.164-.943-2.107-2.107-2.107Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M113.893 116H22.7192c-1.1637 0-2.107.943-2.107 2.107v71.786c0 1.164.9433 2.107 2.107 2.107h91.1738c1.164 0 2.107-.943 2.107-2.107v-71.786c0-1.164-.943-2.107-2.107-2.107ZM193.107 116h91.174c1.163 0 2.107.943 2.107 2.107v71.786c0 1.164-.944 2.107-2.107 2.107h-91.174c-1.164 0-2.107-.943-2.107-2.107v-71.786c0-1.164.943-2.107 2.107-2.107Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M193.107 116h91.174c1.163 0 2.107.943 2.107 2.107v71.786c0 1.164-.944 2.107-2.107 2.107h-91.174c-1.164 0-2.107-.943-2.107-2.107v-71.786c0-1.164.943-2.107 2.107-2.107Z"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M187.893 9h-19.786c-1.164 0-2.107.9433-2.107 2.107v19.786c0 1.1637.943 2.107 2.107 2.107h19.786c1.164 0 2.107-.9433 2.107-2.107V11.107C190 9.9433 189.057 9 187.893 9Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M173 20.6267 177.125 25 184 17"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M187.893 9h-19.786c-1.164 0-2.107.9433-2.107 2.107v19.786c0 1.1637.943 2.107 2.107 2.107h19.786c1.164 0 2.107-.9433 2.107-2.107V11.107C190 9.9433 189.057 9 187.893 9Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M173 20.6267 177.125 25 184 17"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M77.893 44H58.107C56.9433 44 56 44.9433 56 46.107v19.786c0 1.1637.9433 2.107 2.107 2.107h19.786C79.0567 68 80 67.0567 80 65.893V46.107C80 44.9433 79.0567 44 77.893 44Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M63 55.6267 67.125 60 74 52"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M77.893 44H58.107C56.9433 44 56 44.9433 56 46.107v19.786c0 1.1637.9433 2.107 2.107 2.107h19.786C79.0567 68 80 67.0567 80 65.893V46.107C80 44.9433 79.0567 44 77.893 44Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M63 55.6267 67.125 60 74 52"
    />
    <path
      fill="#fff"
      d="M76.1202 144.942v26.827H56v-31.298c0-1.186.4711-2.323 1.3096-3.161a4.4694 4.4694 0 0 1 3.1616-1.31"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.236}
      d="M76.1202 144.942v26.827H56v-31.298c0-1.186.4711-2.323 1.3096-3.161a4.4694 4.4694 0 0 1 3.1616-1.31"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.236}
      d="M56 156.12c0-1.186.4711-2.323 1.3096-3.162a4.4726 4.4726 0 0 1 3.1616-1.309"
    />
    <path
      fill="#BDB2F5"
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.236}
      d="M85.0625 144.942H64.9423v-4.471c0-1.186-.4711-2.323-1.3096-3.161a4.4693 4.4693 0 0 0-3.1615-1.31h20.1202c1.1858 0 2.323.471 3.1615 1.31a4.4687 4.4687 0 0 1 1.3096 3.161v4.471Z"
    />
    <path
      fill="#FDC7BD"
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.236}
      d="M85.0625 160.591H64.9423v-4.471c0-1.186-.4711-2.323-1.3096-3.162a4.4726 4.4726 0 0 0-3.1615-1.309h20.1202c1.1858 0 2.323.471 3.1615 1.309a4.4727 4.4727 0 0 1 1.3096 3.162v4.471Z"
    />
    <path
      fill="#8C77EE"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M165.893 72h-33.786c-1.164 0-2.107.9433-2.107 2.107v19.786c0 1.1637.943 2.107 2.107 2.107h33.786c1.164 0 2.107-.9433 2.107-2.107V74.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      fill="#8C77EE"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M165.893 72h-33.786c-1.164 0-2.107.9433-2.107 2.107v19.786c0 1.1637.943 2.107 2.107 2.107h33.786c1.164 0 2.107-.9433 2.107-2.107V74.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M139 83.5h20M154 78l5.5 5.5L154 89M139 83.5h20"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="m154 78 5.5 5.5L154 89"
    />
    <path
      fill="url(#TransferPowerOfficeGoIllustration__a)"
      d="M242.217 135h-7.419l-3.71 6.501h7.42l3.709-6.501Z"
    />
    <path
      fill="url(#TransferPowerOfficeGoIllustration__b)"
      d="m242.198 135-3.71 6.501 3.71 6.502 3.709-6.502-3.709-6.501Z"
    />
    <path
      fill="#DE3F6D"
      d="m231.088 141.5 3.71 6.502h7.419l-3.709-6.502h-7.42Z"
    />
    <path fill="#F1663B" d="m234.802 160.999-3.709 6.502h7.419l-3.71-6.502Z" />
    <path fill="#F05930" d="M242.21 160.999h-7.419l3.709 6.502 3.71-6.502Z" />
    <path
      fill="url(#TransferPowerOfficeGoIllustration__c)"
      d="M245.907 167.498h-7.417l3.709 6.501 3.709-6.502-.001.001Z"
    />
    <path
      fill="url(#TransferPowerOfficeGoIllustration__d)"
      d="m242.198 160.999-3.71 6.501h7.42l-3.71-6.501Z"
    />
    <path
      fill="url(#TransferPowerOfficeGoIllustration__e)"
      d="M238.508 167.498h-7.42l3.71 6.502h7.419l-3.709-6.502Z"
    />
    <path
      fill="url(#TransferPowerOfficeGoIllustration__f)"
      d="m223.71 154.499-3.709 6.502h7.42l3.709-6.502h-7.42Z"
    />
    <path
      fill="url(#TransferPowerOfficeGoIllustration__g)"
      d="M231.103 154.497 227.394 161l3.709 6.501 3.709-6.501-3.709-6.503Z"
    />
    <path
      fill="#F16B41"
      d="m220.001 160.999 3.709 6.502h7.42l-3.709-6.502h-7.42Z"
    />
    <path
      fill="url(#TransferPowerOfficeGoIllustration__h)"
      d="M231.131 141.499h-7.421l-3.71 6.502h7.42l3.71-6.502h.001Z"
    />
    <path
      fill="url(#TransferPowerOfficeGoIllustration__i)"
      d="m231.103 141.499-3.709 6.502 3.709 6.501 3.709-6.501-3.709-6.502Z"
    />
    <path
      fill="#DE3F6D"
      d="m220.001 148 3.707 6.501h7.421l-3.71-6.502-7.418.001Z"
    />
    <path
      fill="url(#TransferPowerOfficeGoIllustration__j)"
      d="m245.871 154.499 3.709 6.502 3.71-6.502h-7.419Z"
    />
    <path fill="#F16238" d="m245.895 154.499-3.709 6.502h7.419l-3.71-6.502Z" />
    <path
      fill="url(#TransferPowerOfficeGoIllustration__k)"
      d="m249.58 160.999-3.709 6.501h7.419l-3.71-6.501Z"
    />
    <path
      fill="url(#TransferPowerOfficeGoIllustration__l)"
      d="M253.29 154.497 249.58 161l3.708 6.499L257 161l-3.71-6.503Z"
    />
    <path fill="#F68C33" d="m242.185 160.999 3.71 6.502 3.709-6.502h-7.419Z" />
    <path
      fill="url(#TransferPowerOfficeGoIllustration__m)"
      d="m245.871 141.499-3.71 6.502h7.42l3.71-6.502h-7.42Z"
    />
    <path
      fill="url(#TransferPowerOfficeGoIllustration__n)"
      d="m253.29 141.499-3.71 6.502 3.708 6.5 3.712-6.5-3.71-6.502Z"
    />
    <path fill="#F05B40" d="M249.58 148h-7.42l3.711 6.501h7.42L249.58 148Z" />
    <defs>
      <linearGradient
        id="TransferPowerOfficeGoIllustration__a"
        x1={-79.586}
        x2={-78.974}
        y1={-338.273}
        y2={-338.273}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DD2E68" />
        <stop offset={1} stopColor="#EE402F" />
      </linearGradient>
      <linearGradient
        id="TransferPowerOfficeGoIllustration__b"
        x1={239.238}
        x2={248.842}
        y1={146.69}
        y2={141.211}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6B0233" />
        <stop offset={1} stopColor="#EC1651" />
      </linearGradient>
      <linearGradient
        id="TransferPowerOfficeGoIllustration__c"
        x1={240.448}
        x2={243.138}
        y1={170.566}
        y2={164.436}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F79748" />
        <stop offset={0.103} stopColor="#F79D50" />
        <stop offset={0.408} stopColor="#F9AE65" />
        <stop offset={0.709} stopColor="#FAB771" />
        <stop offset={1} stopColor="#FBBB76" />
      </linearGradient>
      <linearGradient
        id="TransferPowerOfficeGoIllustration__d"
        x1={242.198}
        x2={242.198}
        y1={166.785}
        y2={162.351}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F26C41" />
        <stop offset={0.185} stopColor="#F27138" />
        <stop offset={0.657} stopColor="#F37C26" />
        <stop offset={1} stopColor="#F48120" />
      </linearGradient>
      <linearGradient
        id="TransferPowerOfficeGoIllustration__e"
        x1={231.088}
        x2={242.217}
        y1={170.749}
        y2={170.749}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F79748" />
        <stop offset={1} stopColor="#FBBB76" />
      </linearGradient>
      <linearGradient
        id="TransferPowerOfficeGoIllustration__f"
        x1={232.187}
        x2={219.033}
        y1={157.75}
        y2={157.75}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F05A30" />
        <stop offset={1} stopColor="#DE4D6F" />
      </linearGradient>
      <linearGradient
        id="TransferPowerOfficeGoIllustration__g"
        x1={229.078}
        x2={236.388}
        y1={164.549}
        y2={160.379}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A60A25" />
        <stop offset={1} stopColor="#F2744A" />
      </linearGradient>
      <linearGradient
        id="TransferPowerOfficeGoIllustration__h"
        x1={-79.297}
        x2={-78.685}
        y1={-341.761}
        y2={-341.761}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DD2E68" />
        <stop offset={1} stopColor="#EE402F" />
      </linearGradient>
      <linearGradient
        id="TransferPowerOfficeGoIllustration__i"
        x1={227.928}
        x2={238.383}
        y1={153.566}
        y2={147.602}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6B0233" />
        <stop offset={1} stopColor="#EC1651" />
      </linearGradient>
      <linearGradient
        id="TransferPowerOfficeGoIllustration__j"
        x1={249.58}
        x2={249.58}
        y1={154.467}
        y2={157.048}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE402F" />
        <stop offset={1} stopColor="#F05B40" />
      </linearGradient>
      <linearGradient
        id="TransferPowerOfficeGoIllustration__k"
        x1={245.871}
        x2={253.29}
        y1={164.25}
        y2={164.25}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F79748" />
        <stop offset={1} stopColor="#FBBB76" />
      </linearGradient>
      <linearGradient
        id="TransferPowerOfficeGoIllustration__l"
        x1={250.507}
        x2={258.903}
        y1={165.873}
        y2={161.082}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F16238" />
        <stop offset={1} stopColor="#F79748" />
      </linearGradient>
      <linearGradient
        id="TransferPowerOfficeGoIllustration__m"
        x1={241.438}
        x2={255.695}
        y1={144.75}
        y2={144.75}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DD2E68" />
        <stop offset={1} stopColor="#F05A30" />
      </linearGradient>
      <linearGradient
        id="TransferPowerOfficeGoIllustration__n"
        x1={251.42}
        x2={256.525}
        y1={151.276}
        y2={148.363}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B00D28" />
        <stop offset={1} stopColor="#EF4E28" />
      </linearGradient>
    </defs>
  </svg>
);
