import * as React from 'react';
export const HourglassIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={62}
    height={72}
    fill="none"
    viewBox="0 0 62 72"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#FFF1D7"
      d="M12.0698 16.2744 12.6617 11h36.6766l.5919 5.2744c.5014 4.4675-1.7247 8.8314-5.8318 11.4325L31 36.0023l-13.0985-8.2954c-4.107-2.6011-6.3331-6.965-5.8317-11.4325ZM12.07 55.727 12.6617 61h36.6766l.5917-5.273c.5015-4.4682-1.7254-8.8328-5.8337-11.4337L31 36.0023l-13.0963 8.291c-4.1083 2.6009-6.3352 6.9655-5.8337 11.4337Z"
    />
    <path fill="#1D1D1D" d="M29.5 33h3v23h-3V33Z" />
    <path
      fill="#1D1D1D"
      d="M22.6578 31.3581 22 30h18l-.0811.9794-1.3927 1.3449a3 3 0 0 1-.7481.5281L31.4491 36h-.8982l-4.7691-2.2064-3.124-2.4355Z"
    />
    <path
      stroke="#1D1D1D"
      strokeWidth={3}
      d="M12.3377 11.7644 12.4212 11h37.1576l.0835.7644c.8204 7.5029-2.653 14.8286-8.981 18.9422L31 37l-9.6813-6.2934c-6.328-4.1136-9.8014-11.4393-8.981-18.9422Z"
    />
    <rect
      width={49}
      height={7}
      x={6.5}
      y={4.5}
      fill="#F9B0A3"
      stroke="#1D1D1D"
      strokeWidth={3}
      rx={3.5}
    />
    <path
      stroke="#1D1D1D"
      strokeWidth={3}
      d="m31 37-9.637 5.7817C15.0565 46.5652 11.5563 53.6966 12.4212 61h37.1576c.8649-7.3034-2.6353-14.4348-8.9418-18.2183L31 37Z"
    />
    <path fill="#1D1D1D" d="M12.5161 59.7097h37.8387V63H12.5161v-3.2903Z" />
    <path fill="#1D1D1D" d="M12 59h38v4H12v-4Z" />
    <path
      fill="#1D1D1D"
      d="M32.5 49v3.8983C32.5 56.4865 36.0892 59 40.4286 59H42v3H20v-3h1.5714c4.3394 0 7.9286-2.5135 7.9286-6.1017V49h3Z"
    />
    <rect
      width={49}
      height={7}
      x={6.5}
      y={60.5}
      fill="#F9B0A3"
      stroke="#1D1D1D"
      strokeWidth={3}
      rx={3.5}
    />
  </svg>
);
