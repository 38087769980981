import * as React from 'react';
export const WarningSignIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={62}
    height={62}
    fill="none"
    viewBox="0 0 62 62"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={5.602}
      d="M30.8127 31.2367v-9.804"
    />
    <path
      fill="#F8D59A"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M23.5903 11.2047c3.21-5.6024 11.2349-5.6023 14.4449 0l16.8525 29.412c3.2099 5.6023-.8025 12.6052-7.2225 12.6052H13.9603c-6.42 0-10.4324-7.0029-7.2224-12.6052l16.8524-29.412Z"
    />
    <circle
      cx={3}
      cy={3}
      r={3}
      fill="#1D1D1D"
      transform="matrix(1 0 0 -1 28 46)"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M31 34V22"
    />
  </svg>
);
