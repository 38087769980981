import * as React from 'react';
export const MailMysteryIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={120}
    height={120}
    fill="none"
    viewBox="0 0 120 120"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M10.4711 66.9154a4.8 4.8 0 0 1 2.3132-4.1056L57.513 35.7172a4.7998 4.7998 0 0 1 4.9735 0l44.7285 27.0926a4.7998 4.7998 0 0 1 2.313 4.1056V112.2c0 2.651-2.149 4.8-4.8 4.8H15.2711c-2.651 0-4.8-2.149-4.8-4.8V66.9154Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M10.4711 66.9154a4.8 4.8 0 0 1 2.3132-4.1056L57.513 35.7172a4.7998 4.7998 0 0 1 4.9735 0l44.7285 27.0926a4.7998 4.7998 0 0 1 2.313 4.1056V112.2c0 2.651-2.149 4.8-4.8 4.8H15.2711c-2.651 0-4.8-2.149-4.8-4.8V66.9154Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.602}
      d="M10.4711 66.9154a4.8 4.8 0 0 1 2.3132-4.1056L57.513 35.7172a4.7998 4.7998 0 0 1 4.9735 0l44.7285 27.0926a4.7998 4.7998 0 0 1 2.313 4.1056V112.2c0 2.651-2.149 4.8-4.8 4.8H15.2711c-2.651 0-4.8-2.149-4.8-4.8V66.9154Z"
    />
    <rect
      width={66.673}
      height={70.871}
      x={26.664}
      y={27.302}
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3.6}
      rx={4.8}
    />
    <path
      fill="#fff"
      d="m59.8404 96.5863 43.9726-21.5895-1.905 32.7012H16.8203V74.9968l43.0201 21.5895Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="m59.8404 96.5863 43.9726-21.5895-1.905 32.7012H16.8203V74.9968l43.0201 21.5895Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.602}
      d="m10.4711 71.5046 49.5287 25.2406 49.5282-25.2406M99.2992 117.152 59.1367 96.3015"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.6}
      d="m85.6071 9.5313-6.5453 6.9702M58.7078 2.4016v10.4905M33.7047 9.5313l6.5453 6.9702"
    />
    <path
      fill="#BDB1F5"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M59.8707 44.5a14.0066 14.0066 0 0 0-9.9044 4.1026l-.4223.4223c-1.392 1.3919-1.392 3.6487 0 5.0406 1.3919 1.392 3.6487 1.392 5.0406 0l.4223-.4223a6.8787 6.8787 0 0 1 4.8638-2.0146h1.5066a2.8328 2.8328 0 0 1 2.6873 1.9369c.4947 1.4841-.3074 3.0883-1.7916 3.583l-4.374 1.4581a3.5642 3.5642 0 0 0-2.4372 3.3813v3.481c0 1.9685 1.5958 3.5642 3.5643 3.5642 1.9685 0 3.5643-1.5958 3.5643-3.5642v-.912l1.9369-.6456c5.2191-1.7397 8.0397-7.381 6.3-12.6001-1.3558-4.0676-5.1624-6.8112-9.45-6.8112h-1.5066Z"
    />
    <path
      fill="#BDB1F5"
      stroke="#1D1D1D"
      strokeWidth={3}
      d="M62.5075 75.9116c0 1.9224-1.5585 3.4809-3.4809 3.4809-1.9225 0-3.4809-1.5585-3.4809-3.4809 0-1.9225 1.5584-3.4809 3.4809-3.4809 1.9224 0 3.4809 1.5584 3.4809 3.4809Z"
    />
  </svg>
);
