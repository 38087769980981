import * as React from 'react';
export const BookDoneIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={142}
    height={126}
    fill="none"
    viewBox="0 0 142 126"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#99E2CD"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M36.5383 37.675a3.6875 3.6875 0 0 0-.5569 2.7694l10.8301 54.9372c.3938 1.9977 2.3324 3.2981 4.3302 2.9046l39.3871-7.7577c1.998-.3936 3.2987-2.3322 2.9053-4.3302L81.679 26.4979c-.3933-1.9978-2.3316-3.2986-4.3294-2.9056l-34.6261 6.8106a3.687 3.687 0 0 0-2.3491 1.5618l-3.8361 5.7103Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m87.4022 35.66-35.6498 7.0275c-1.1417.225-1.8848 1.333-1.6597 2.4747L60.7895 99.426c.225 1.142 1.333 1.885 2.4747 1.66l35.6498-7.0278c1.142-.2251 1.885-1.333 1.66-2.4747L89.8769 37.3197c-.225-1.1417-1.333-1.8848-2.4747-1.6597Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m93.6164 34.4814-35.6498 7.0275c-1.1417.225-1.8847 1.333-1.6597 2.4747l10.6968 54.2638c.2251 1.1417 1.333 1.8846 2.4747 1.6597l35.6496-7.0275c1.142-.2251 1.885-1.333 1.66-2.4747L96.0912 36.1411c-.2251-1.1417-1.3331-1.8848-2.4748-1.6597Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m99.8307 33.3029-35.6498 7.0275c-1.1417.225-1.8848 1.333-1.6598 2.4747l10.6968 54.2638c.2251 1.1416 1.3331 1.8847 2.4747 1.6597l35.6494-7.0275c1.142-.2251 1.885-1.3331 1.66-2.4747l-10.697-54.2638c-.225-1.1417-1.333-1.8848-2.4743-1.6597Z"
    />
    <path
      fill="#99E2CD"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M86.5948 21.7442 44.095 30.122c-1.1416.225-1.8847 1.333-1.6597 2.4747l11.0246 55.9266c.2251 1.1417 1.333 1.8848 2.4747 1.6597l42.4998-8.3778c1.1417-.225 1.8846-1.333 1.6596-2.4747L89.0695 23.4039c-.225-1.1417-1.333-1.8848-2.4747-1.6597Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m48.501 97.5406 5.2717-7.8733M106.943 12.0087l7.16-9.4373M24.8008 123.482l7.1601-9.437M22.833 22.8809l-9.0292-7.6682M131.883 110.046l-9.029-7.668M139.343 49.5649l-11.419 1.8722M14.2761 72.5488 2.8571 74.421"
    />
  </svg>
);
