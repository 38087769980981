import * as React from 'react';
export const BookIsHappyIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={75}
    height={82}
    fill="none"
    viewBox="0 0 75 82"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#99E2CD"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M5.584 9.6836a3.6872 3.6872 0 0 0-1.077 2.6113l.1057 55.9945c.0038 2.0362 1.6574 3.6838 3.6936 3.6803l40.1437-.069c2.0364-.0035 3.6844-1.6571 3.681-3.6934l-.1011-60.8466c-.0034-2.0362-1.6565-3.6842-3.6927-3.6812l-35.2894.0514a3.6871 3.6871 0 0 0-2.6048 1.083L5.584 9.6835Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M55.948 17.4835H19.6122c-1.1637 0-2.107.9434-2.107 2.107v55.3081c0 1.1636.9433 2.107 2.107 2.107H55.948c1.1637 0 2.107-.9434 2.107-2.107V19.5905c0-1.1636-.9433-2.107-2.107-2.107Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M62.2728 17.5172H25.937c-1.1637 0-2.107.9433-2.107 2.107v55.3081c0 1.1636.9433 2.1069 2.107 2.1069h36.3358c1.1637 0 2.107-.9433 2.107-2.107v-55.308c0-1.1637-.9433-2.107-2.107-2.107Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M68.5978 17.5509H32.2619c-1.1636 0-2.1069.9433-2.1069 2.107v55.308c0 1.1637.9433 2.107 2.107 2.107h36.3358c1.1637 0 2.107-.9433 2.107-2.107v-55.308c0-1.1637-.9433-2.107-2.107-2.107Z"
    />
    <path
      fill="#99E2CD"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M57.8229 3.6772H14.5052c-1.1636 0-2.107.9434-2.107 2.107v57.0029c0 1.1637.9434 2.107 2.107 2.107h43.3177c1.1636 0 2.107-.9433 2.107-2.107V5.7842c0-1.1636-.9434-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m5.8573 70.7322 6.6823-6.7177M49.5225 33.4844c-7.6976 6.6666-18.3024 6.6666-26 0M27.5303 25.4845l2.7026 2.7026 2.7025-2.7026M40.4665 25.4845l2.7026 2.7026 2.7025-2.7026"
    />
  </svg>
);
