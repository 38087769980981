import * as React from 'react';
export const TreasureChestIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={93}
    height={68}
    fill="none"
    viewBox="0 0 93 68"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M89.9879 56.3287H3.0627v8.8724H89.988v-8.8724Z"
    />
    <path
      fill="#fff"
      d="M67.7213 3.1303H25.3274c-5.5812 0-10.0938 4.4009-10.0938 9.844v42.9083h62.5815V12.9743c0-5.4431-4.5125-9.844-10.0938-9.844Z"
    />
    <path
      fill="#B039D3"
      fillOpacity={0.5}
      d="M67.7213 3.1303H25.3274c-5.5812 0-10.0938 4.4009-10.0938 9.844v42.9083h62.5815V12.9743c0-5.4431-4.5125-9.844-10.0938-9.844Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M67.7213 3.1303H25.3274c-5.5812 0-10.0938 4.4009-10.0938 9.844v42.9083h62.5815V12.9743c0-5.4431-4.5125-9.844-10.0938-9.844Z"
    />
    <path
      fill="#fff"
      d="M67.7223 3.1306c-5.5813 0-10.0938 4.4009-10.0938 9.8441v42.9082h20.1876V12.9747c0-5.4432-4.5125-9.844-10.0938-9.844Z"
    />
    <path
      fill="#B039D3"
      fillOpacity={0.5}
      d="M67.7223 3.1306c-5.5813 0-10.0938 4.4009-10.0938 9.8441v42.9082h20.1876V12.9747c0-5.4432-4.5125-9.844-10.0938-9.844Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M67.7223 3.1306c-5.5813 0-10.0938 4.4009-10.0938 9.8441v42.9082h20.1876V12.9747c0-5.4432-4.5125-9.844-10.0938-9.844Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M77.8147 21.8324H15.2333"
    />
    <path
      fill="#FDC846"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M38.5923 45.6535c-4.3764 0-8.0007-3.477-8.0007-7.8231V22.0506h15.9329v15.8467c0 4.2792-3.5558 7.7562-7.9322 7.7562Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M38.5573 38.3264v-5.3437M38.5585 32.9828c1.4756 0 2.6719-1.1963 2.6719-2.6719 0-1.4757-1.1963-2.6719-2.6719-2.6719s-2.6719 1.1962-2.6719 2.6719c0 1.4756 1.1963 2.6719 2.6719 2.6719Z"
    />
  </svg>
);
