import * as React from 'react';
export const TransformedIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={121}
    height={177}
    fill="none"
    viewBox="0 0 121 177"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#000"
      d="M60.7407 174.445c25.4901 0 46.1543-3.357 46.1543-7.5 0-4.142-20.6642-7.5-46.1543-7.5-25.4901 0-46.1538 3.358-46.1538 7.5 0 4.143 20.6637 7.5 46.1538 7.5Z"
    />
    <path
      fill="#FEE3B5"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3.479}
      d="M37.7671 151.785c-3.4037 9.114-1.4466 15.804 3.5464 17.619 4.8993 1.882 10.9554-1.751 14.3592-10.864l-17.9056-6.755ZM80.924 152.939c3.4038 9.114 1.4467 15.803-3.5464 17.618-4.8992 1.883-10.9554-1.75-14.3591-10.864l17.9055-6.754Z"
    />
    <path
      fill="#fff"
      d="m14.2075 120.593 50.7691 2.777 47.3054 5.273c5.258-26.486-6.602-114.4026-52.5765-112.4408-45.9742 1.9619-48.7994 85.6298-45.498 104.3908Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      d="m14.2075 120.593 50.7691 2.777 47.3054 5.273c5.258-26.486-6.602-114.4026-52.5765-112.4408-45.9742 1.9619-48.7994 85.6298-45.498 104.3908Z"
    />
    <path
      stroke="#000"
      strokeWidth={3}
      d="m14.2075 120.593 50.7691 2.777 47.3054 5.273c5.258-26.486-6.602-114.4026-52.5765-112.4408-45.9742 1.9619-48.7994 85.6298-45.498 104.3908Z"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M10.169 129.129c2.5588 14.541 20.3136 31.022 49.5366 31.758 34.8475 0 50.0274-17.217 52.5764-31.758 2.55-14.541-1.152-61.0359-14.9945-85.9343 0 0-10.5853 7.0138-37.5819 4.7061-26.9967-2.3077-30.4982-11.9932-30.4982-11.9932C17.5681 56.0002 11.0086 80.0617 9.2818 101.176c-.841 10.283-.5357 19.867.8872 27.953Z"
    />
    <path
      fill="#fff"
      d="M50.6316 21.6041c.9982-2.2878 2.863-4.7214 2.1072-7.3904C51.0122 8.3278 41.0285.4805 36.1341 2.2555c-4.8321 1.8722-7.1609 12.9717-5.8225 19.1071.6519 3.1475 4.9819 4.6636 7.554 5.8906"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      d="M50.6316 21.6041c.9982-2.2878 2.863-4.7214 2.1072-7.3904C51.0122 8.3278 41.0285.4805 36.1341 2.2555c-4.8321 1.8722-7.1609 12.9717-5.8225 19.1071.6519 3.1475 4.9819 4.6636 7.554 5.8906"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M50.6316 21.6041c.9982-2.2878 2.863-4.7214 2.1072-7.3904C51.0122 8.3278 41.0285.4805 36.1341 2.2555c-4.8321 1.8722-7.1609 12.9717-5.8225 19.1071.6519 3.1475 4.9819 4.6636 7.554 5.8906"
    />
    <path
      fill="#fff"
      d="M87.8656 31.7532c2.4882-.1987 6.1373.4034 8.1346-1.5218 4.3758-4.2982 6.5878-16.8026 2.7147-20.2816-3.9295-3.3782-14.8156-.1976-19.5949 3.8759-2.4681 2.0591-1.7338 6.4308-1.6028 9.2776"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      d="M87.8656 31.7532c2.4882-.1987 6.1373.4034 8.1346-1.5218 4.3758-4.2982 6.5878-16.8026 2.7147-20.2816-3.9295-3.3782-14.8156-.1976-19.5949 3.8759-2.4681 2.0591-1.7338 6.4308-1.6028 9.2776"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M87.8656 31.7532c2.4882-.1987 6.1373.4034 8.1346-1.5218 4.3758-4.2982 6.5878-16.8026 2.7147-20.2816-3.9295-3.3782-14.8156-.1976-19.5949 3.8759-2.4681 2.0591-1.7338 6.4308-1.6028 9.2776"
    />
    <path
      fill="#fff"
      d="M11.7023 102.33c-9.2286 12.549-10.5236 31.077-1.9268 34.157 7.8141 2.676 18.2778-5.211 18.5822-26.428"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M11.7023 102.33c-9.2286 12.549-10.5236 31.077-1.9268 34.157 7.8141 2.676 18.2778-5.211 18.5822-26.428"
    />
    <path
      fill="#fff"
      d="M110.27 101.224c9.228 12.549 10.523 31.077 1.927 34.158-7.815 2.675-18.2782-5.212-18.5826-26.428"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M110.27 101.224c9.228 12.549 10.523 31.077 1.927 34.158-7.815 2.675-18.2782-5.212-18.5826-26.428"
    />
    <path
      fill="#FEE3B5"
      stroke="#000"
      strokeWidth={3}
      d="M67.3176 107.522c0 4.109-3.8068 7.731-8.8846 7.731-5.0778 0-8.8846-3.622-8.8846-7.731 0-4.108 3.8068-7.7304 8.8846-7.7304 5.0778 0 8.8846 3.6224 8.8846 7.7304Z"
    />
    <path
      stroke="#000"
      strokeWidth={3}
      d="M67.0869 105.792s-3.5431 3.35-8.6538 3.461c-6.2625.137-8.6539-3.461-8.6539-3.461"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M79.0717 91.957c.2902-2.0333-.8447-4.0014-2.8199-4.6983-2.3444-.8471-4.9413.2468-5.7885 2.5911l-.4885 1.1996M40.0942 92.091c-.3578-2.0003.7647-4.0471 2.712-4.8122 2.2818-1.0037 4.865.1222 5.8686 2.4039l.4737 1.2055"
    />
  </svg>
);
