import * as React from 'react';
export const DuoTeamIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={151}
    height={85}
    fill="none"
    viewBox="0 0 151 85"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M36.6906 46C25.1312 46 14.9508 51.9038 9 60.8606c7.0338 11.9406 20.0234 19.9531 34.8844 19.9531 9.5446 0 18.3172-3.3051 25.2358-8.8332C65.8159 57.1149 52.5512 46 36.6906 46Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.899}
      d="M9 60.8606C14.9508 51.9038 25.1312 46 36.6906 46c15.8606 0 29.1253 11.1149 32.4296 25.9805"
    />
    <path
      fill="#FED491"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.899}
      d="M43.6802 57.2171c10.5662 0 19.1319-8.5656 19.1319-19.1319 0-10.5662-8.5657-19.1318-19.1319-19.1318-10.5662 0-19.1319 8.5656-19.1319 19.1318 0 10.5663 8.5657 19.1319 19.1319 19.1319Z"
    />
    <path
      fill="#1D1D1D"
      d="M33.785 40.0578c.9772-.5692 1.1909-2.0238.4773-3.2489-.7136-1.2252-2.0843-1.7569-3.0615-1.1878-.9772.5692-1.1909 2.0238-.4773 3.249.7136 1.2251 2.0843 1.7569 3.0615 1.1877ZM50.2899 30.4405c.9772-.5692 1.1909-2.0238.4773-3.2489-.7136-1.2252-2.0842-1.7569-3.0614-1.1878-.9772.5692-1.1909 2.0238-.4773 3.249.7136 1.2251 2.0842 1.7569 3.0614 1.1877Z"
    />
    <path
      fill="#FFB938"
      d="M54.9276 38.2365c1.8736 0 3.3924-1.5188 3.3924-3.3924s-1.5188-3.3924-3.3924-3.3924-3.3924 1.5188-3.3924 3.3924 1.5188 3.3924 3.3924 3.3924ZM35.3379 49.6362c1.8736 0 3.3924-1.5189 3.3924-3.3924 0-1.8736-1.5188-3.3924-3.3924-3.3924s-3.3924 1.5188-3.3924 3.3924c0 1.8735 1.5188 3.3924 3.3924 3.3924Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.899}
      d="M47.3492 35.1785c-1.3142 3.1785-4.5843 5.0733-8.0073 4.676"
    />
    <path
      stroke="#1D1D1D"
      strokeWidth={2.994}
      d="M82.104 42.1377c0 21.5176-17.4435 38.9611-38.9612 38.9611-21.5176 0-38.9611-17.4435-38.9611-38.9611S21.6252 3.1766 43.1428 3.1766c21.5177 0 38.9612 17.4435 38.9612 38.9611Z"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M79 70.2267c7.3577 7.6227 17.6817 12.3636 29.113 12.3636 22.344 0 40.458-18.1137 40.458-40.458 0-22.3443-18.114-40.458-40.458-40.458-11.4313 0-21.7552 4.7408-29.1129 12.3635a47.2668 47.2668 0 0 1 1.7437 2.511C87.5809 9.2376 97.3132 4.6681 108.113 4.6681c20.691 0 37.464 16.7733 37.464 37.4642s-16.773 37.4642-37.464 37.4642c-10.7998 0-20.5322-4.5696-27.3693-11.8808A47.2668 47.2668 0 0 1 79 70.2267Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M107.081 53.0942c-11.9667 0-22.2673 7.134-26.8808 17.381 7.0476 6.5753 16.7201 10.6325 27.3928 10.6325 10.307 0 19.682-3.784 26.659-9.9655-4.46-10.6031-14.946-18.048-27.171-18.048Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M107.081 53.0942c-11.9667 0-22.2673 7.134-26.8808 17.381 7.0476 6.5753 16.7201 10.6325 27.3928 10.6325 10.307 0 19.682-3.784 26.659-9.9655-4.46-10.6031-14.946-18.048-27.171-18.048Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.994}
      d="m96.3272 61.7706.5637-10.3923c.0144-.2656.3003-.423.534-.294l9.5941 5.2947c.256.1414.252.5112-.008.6415l-10.1575 5.0976c-.2486.1248-.5414-.0685-.5263-.3475Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.994}
      d="m116.158 62.9364.568-10.3921c.014-.2656-.253-.4582-.499-.3597l-10.102 4.0418c-.27.108-.306.4757-.062.638l9.534 6.3503c.234.1555.545.0006.561-.2783Z"
    />
    <path
      fill="#FED491"
      d="M107.658 56.9527c10.099 0 18.286-8.1868 18.286-18.2859 0-10.099-8.187-18.2859-18.286-18.2859-10.099 0-18.2859 8.1869-18.2859 18.2859 0 10.0991 8.1869 18.2859 18.2859 18.2859Z"
    />
    <path
      fill="#FFB938"
      d="M95.5502 45.8982c1.1678.3346 2.3857-.3408 2.7203-1.5086s-.3408-2.3857-1.5086-2.7203-2.3857.3408-2.7203 1.5086.3409 2.3857 1.5086 2.7203ZM112.67 51.4138c1.168.3346 2.386-.3408 2.72-1.5086.335-1.1678-.341-2.3857-1.508-2.7203-1.168-.3346-2.386.3409-2.721 1.5086-.334 1.1678.341 2.3857 1.509 2.7203Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.994}
      d="M99.862 47.5122c1.666 2.8994 5.367 3.8992 8.266 2.2332M113.692 45.2806c.54-1.5505-.279-3.2452-1.83-3.785-1.55-.5399-3.245.2795-3.785 1.83M103.598 41.8041c.54-1.5506-.279-3.2452-1.83-3.7851-1.551-.5399-3.2452.2795-3.7851 1.8301"
    />
    <path
      fill="#1D1D1D"
      fillOpacity={0.66}
      d="M102.808 32.6311c-6.9263-2.5468-9.7315-2.4338-10.1312-4.4583 2.4004-1.9829 10.0972-9.4009 17.4672-7.4852 3.198.8314 10.866 5.1086 12.783 9.5117 6.457 10.6992-1.772 22.4062-1.134 19.4467.833-3.8642 2.312-5.784-.211-9.8515-1.778-2.8654-8.443-5.0928-13.244-6.284l-.733-3.7757c-.977-.1509-3.801 2.9778-4.797 2.8963Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.994}
      d="m92.9541 28.4385 10.9019 3.6085 3.411-2.2042.968 3.4897 2.583.8449c6.62 2.0461 14.19 5.8857 12.095 13.4457l-.498 1.8569"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.994}
      d="M107.658 56.9527c10.099 0 18.286-8.1868 18.286-18.2859 0-10.099-8.187-18.2859-18.286-18.2859-10.099 0-18.2859 8.1869-18.2859 18.2859 0 10.0991 8.1869 18.2859 18.2859 18.2859Z"
    />
  </svg>
);
