import * as React from 'react';
export const BombIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={70}
    height={101}
    fill="none"
    viewBox="0 0 70 101"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#FED491"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.981}
      d="m39.325 12.766 8.3831-2.5744-2.6398 8.3833 7.7895 4.0927-7.7889 4.0926 2.5751 8.3833-8.3835-2.5744-4.0921 7.7892-4.0932-7.7892-8.4492 2.5744 2.6398-8.3833-7.7895-4.1587 7.7889-4.0926-2.5751-8.3833L31.0737 12.7l4.158-7.7232 4.0933 7.7892Z"
    />
    <path
      fill="#fff"
      stroke="#202020"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.21}
      d="M38.1371 27.3543h-5.9409l-.0006-7.5252c-.0001-1.6502 1.32-2.9704 2.9703-2.9704a2.9585 2.9585 0 0 1 2.9707 2.9704l.0005 7.5252Z"
    />
    <path
      fill="#fff"
      d="m40.9097 33.1633-.0002-2.5084c-.0001-1.8483-1.4524-3.3005-3.3007-3.3005H32.79c-1.8483 0-3.3004 1.4522-3.3002 3.3005l.0001 2.5084C14.4398 35.8697 3.021 49.0058 3.0222 64.7822 3.0236 82.539 17.415 96.9293 35.1717 96.9293c17.7568 0 32.1459-14.3903 32.1446-32.1471-.0012-15.7764-11.422-28.9125-26.4066-31.6189Z"
    />
    <path
      fill="#5A3DE6"
      fillOpacity={0.25}
      d="m40.9097 33.1633-.0002-2.5084c-.0001-1.8483-1.4524-3.3005-3.3007-3.3005H32.79c-1.8483 0-3.3004 1.4522-3.3002 3.3005l.0001 2.5084C14.4398 35.8697 3.021 49.0058 3.0222 64.7822 3.0236 82.539 17.415 96.9293 35.1717 96.9293c17.7568 0 32.1459-14.3903 32.1446-32.1471-.0012-15.7764-11.422-28.9125-26.4066-31.6189Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.981}
      d="m40.9097 33.1633-.0002-2.5084c-.0001-1.8483-1.4524-3.3005-3.3007-3.3005H32.79c-1.8483 0-3.3004 1.4522-3.3002 3.3005l.0001 2.5084C14.4398 35.8697 3.021 49.0058 3.0222 64.7822 3.0236 82.539 17.415 96.9293 35.1717 96.9293c17.7568 0 32.1459-14.3903 32.1446-32.1471-.0012-15.7764-11.422-28.9125-26.4066-31.6189Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.21}
      d="M10.4823 64.7828c-.0006-7.5252 3.3655-14.2583 8.6459-18.747M29.3593 40.7545c1.8482-.4621 3.8285-.6601 5.8088-.6601"
    />
    <path
      fill="#1D1D1D"
      d="M45.0715 62.0756c1.6041 0 2.9043-2.6303 2.904-5.8749-.0002-3.2446-1.3008-5.8749-2.9049-5.8749s-2.9042 2.6303-2.904 5.8749c.0003 3.2446 1.3008 5.8749 2.9049 5.8749ZM25.3343 62.0755c1.6041 0 2.9042-2.6303 2.904-5.8749-.0003-3.2446-1.3008-5.8749-2.9049-5.8749s-2.9043 2.6303-2.904 5.8749c.0002 3.2446 1.3008 5.8749 2.9049 5.8749ZM35.172 89.1401c6.0518 0 10.9573-4.9059 10.9569-10.9577-.0005-6.0518-4.9068-10.9577-10.9586-10.9577-6.0518 0-10.9573 4.9059-10.9569 10.9577.0005 6.0518 4.9068 10.9577 10.9586 10.9577Z"
    />
    <path
      fill="#1D1D1D"
      d="M35.1706 68.5442a9.6077 9.6077 0 0 1 9.6383 9.6375 9.6058 9.6058 0 0 1-9.6368 9.6375 9.6075 9.6075 0 0 1-9.6383-9.6375 9.6059 9.6059 0 0 1 9.6368-9.6375Zm-.0002-2.6404c-6.7991 0-12.2775 5.5449-12.277 12.2779.0005 6.7331 5.5458 12.2779 12.2789 12.2779 6.733 0 12.2775-5.5448 12.277-12.2779-.0005-6.733-5.4798-12.2779-12.2789-12.2779Z"
    />
    <path
      fill="#fff"
      d="M34.8112 76.5999c-3.8062 0-7.0119 2.57-7.9768 6.0693 1.6639 2.652 4.6139 4.4151 7.9755 4.4151 3.3636 0 6.3148-1.7652 7.9778-4.4198-.967-3.4969-4.172-6.0646-7.9765-6.0646Z"
    />
    <path
      fill="#F46147"
      fillOpacity={0.7}
      d="M34.8112 76.5999c-3.8062 0-7.0119 2.57-7.9768 6.0693 1.6639 2.652 4.6139 4.4151 7.9755 4.4151 3.3636 0 6.3148-1.7652 7.9778-4.4198-.967-3.4969-4.172-6.0646-7.9765-6.0646Z"
    />
  </svg>
);
