import * as React from 'react';
export const SuperbossIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={183}
    height={130}
    fill="none"
    viewBox="0 0 183 130"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M72.6656 82.2361c-6.2478 5.1288-21.7275 8.7656-24.9913 9.5116-2.5177.5595-3.2637 6.8073.1866 7.6466 3.6367.8397 22.5667 1.8647 27.882 1.6787"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M72.6656 82.2361c-6.2478 5.1288-21.7275 8.7656-24.9913 9.5116-2.5177.5595-3.2637 6.8073.1866 7.6466 3.6367.8397 22.5667 1.8647 27.882 1.6787"
    />
    <path
      fill="#fff"
      d="M65.1573 90.9012c-6.2478 5.1289-21.7275 8.7656-24.9913 9.5118-2.5178.559-3.2638 6.807.1865 7.646 3.6368.84 22.5668 1.865 27.8821 1.679"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M65.1573 90.9012c-6.2478 5.1289-21.7275 8.7656-24.9913 9.5118-2.5178.559-3.2638 6.807.1865 7.646 3.6368.84 22.5668 1.865 27.8821 1.679"
    />
    <path
      fill="#fff"
      d="M144.887 58.8235c9.698.1865 14.92-4.7558 21.727-10.9104 3.264-2.8908 6.807-9.0453 10.724-6.9006 4.29 2.3313-.746 10.4442-1.585 11.9362-5.595 9.6048-11.284 14.2674-22.847 19.5827"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.011}
      d="M144.887 58.8235c9.698.1865 14.92-4.7558 21.727-10.9104 3.264-2.8908 6.807-9.0453 10.724-6.9006 4.29 2.3313-.746 10.4442-1.585 11.9362-5.595 9.6048-11.284 14.2674-22.847 19.5827"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M100.451 103.998c5.758-10.1749 2.177-23.0905-7.9972-28.8482-10.1744-5.7577-23.09-2.1772-28.8477 7.9973s-2.1772 23.0899 7.9973 28.8479c10.1744 5.758 23.09 2.177 28.8476-7.997Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M152.788 91.8271c13.224-19.3463 8.26-45.7491-11.086-58.9724-19.347-13.2232-45.7495-8.2595-58.9727 11.0868C69.506 63.2878 74.4697 89.6906 93.816 102.914c19.346 13.223 45.749 8.259 58.972-11.0869Z"
    />
    <path
      fill="#fff"
      d="M158.317 55.4662c-1.492-4.8491 5.128-10.6306-.653-13.8012-6.155-3.357-13.522 4.8491-17.158 8.5791"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M158.317 55.4662c-1.492-4.8491 5.128-10.6306-.653-13.8012-6.155-3.357-13.522 4.8491-17.158 8.5791"
    />
    <path
      fill="#fff"
      d="M129.875 50.5239c-.187-4.5693.839-10.2576-3.917-12.2159-4.756-1.9582-9.511 5.5019-16.599 8.8589"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M129.875 50.5239c-.187-4.5693.839-10.2576-3.917-12.2159-4.756-1.9582-9.511 5.5019-16.599 8.8589M28.5508 92.307 3 95.0113M19.2789 122.42l14.0809-4.569"
    />
    <path
      fill="#fff"
      d="M73.4259 75.988c-9.6981-2.3312-14.0809-5.222-20.8883-11.3766-3.2637-2.8908-7.5533-8.6723-11.4698-6.5276-4.2896 2.3313.6527 10.4441 1.492 11.9362 5.5018 9.6048 17.9042 19.5827 29.3741 24.9912"
    />
    <path
      fill="#fff"
      d="m42.2399 57.5249 4.5153 2.064 7.0954 6.4498-6.0629 1.419h-7.0949l-2.3227-6.5789 3.8698-3.3539ZM177.386 40.2017l-4.515 2.064-7.096 6.4498 6.063 1.419h5.549l1.545-6.6706-1.546-3.2622Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M144.047 61.7137c.093 2.1448 1.585 4.103 3.823 4.5693 2.611.5595 5.129-1.119 5.689-3.7301l.28-1.3987M127.729 60.5951c-.933 1.9582-3.171 3.0773-5.315 2.611-2.611-.5595-4.29-3.0773-3.73-5.6883l.279-1.3988M137.8 68.2412c-.56 2.1448-2.705 3.4503-4.849 2.8908-2.145-.5595-3.451-2.7043-2.891-4.849"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M73.4259 75.988c-9.6981-2.3312-14.0809-5.222-20.8883-11.3766-3.2637-2.8908-7.5533-8.6723-11.4698-6.5276-4.2896 2.3313.6527 10.4441 1.492 11.9362 5.5018 9.6048 17.9042 19.5827 29.3741 24.9912"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.011}
      d="M41.2624 67.1919c2.4245.9015 8.9521 1.3022 12.8686-.8014"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M158.571 54.6375c2.695-1.8516 5.234-4.1848 8.043-6.7244 3.264-2.8908 6.808-9.0453 10.724-6.9006 4.29 2.3313-.746 10.4442-1.585 11.9362-4.174 7.1645-8.399 11.5791-15.076 15.5659"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M176.966 50.8037c-2.798 1.9583-9.139 0-11.191-2.1448"
    />
    <path
      fill="#FDC871"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.011}
      d="m127.071 10.9789-3.637 14.4308h-12.462l-4.265-14.2715c-.299-.9989.775-1.8502 1.68-1.331l4.258 2.4447 2.921-6.8588c.392-.9206 1.69-.94 2.109-.0315l3.181 6.8903 4.529-2.5595c.883-.4992 1.934.3027 1.686 1.2865Z"
    />
  </svg>
);
