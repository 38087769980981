import * as React from 'react';
export const CloudyIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={99}
    height={71}
    fill="none"
    viewBox="0 0 99 71"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M95.8856 34.5359c0-10.1403-7.094-18.6532-16.6084-20.7397-2.6707-6.2594-8.8885-10.6828-16.1077-10.6828-5.6753 0-10.7663 2.7124-13.9378 6.9271-2.8376-1.377-6.0091-2.1699-9.3892-2.1699-8.6381 0-16.1077 5.1745-19.4044 12.6024-9.5978.0835-17.3595 7.8869-17.3595 17.4848 0 9.6813 7.8452 17.4848 17.4847 17.4848 2.8377 5.8839 8.8468 9.9317 15.7739 9.9317 5.5083 0 10.1821-2.2535 13.3535-6.1761 3.8809 5.1328 9.6396 8.4295 16.1077 8.4295 8.805 0 16.3164-6.0509 19.5296-14.6889 6.3429-3.714 10.5576-10.5577 10.5576-18.4029Zm-48.6569-4.1312c-5.5918 10.7663-2.5455 21.8247 2.5038 28.7935l-2.5038-28.7935Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M47.2287 30.4047c-5.5918 10.7663-2.5455 21.8247 2.5038 28.7935m46.1531-24.6623c0-10.1403-7.094-18.6532-16.6084-20.7397-2.6707-6.2594-8.8885-10.6828-16.1077-10.6828-5.6753 0-10.7663 2.7124-13.9378 6.9271-2.8376-1.377-6.0091-2.1699-9.3892-2.1699-8.6381 0-16.1077 5.1745-19.4044 12.6024-9.5978.0835-17.3595 7.8869-17.3595 17.4848 0 9.6813 7.8452 17.4848 17.4847 17.4848 2.8377 5.8839 8.8468 9.9317 15.7739 9.9317 5.5083 0 10.1821-2.2535 13.3535-6.1761 3.8809 5.1328 9.6396 8.4295 16.1077 8.4295 8.805 0 16.3164-6.0509 19.5296-14.6889 6.3429-3.714 10.5576-10.5577 10.5576-18.4029Z"
    />
    <path
      fill="#fff"
      d="M86.5799 44.8431c0 2.8377-.4173 5.5501-1.2519 8.0956l1.2519-8.0956Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M86.5799 44.8431c0 2.8377-.4173 5.5501-1.2519 8.0956"
    />
    <path
      fill="#fff"
      d="M44.2241 42.7983c-6.635 3.7974-15.899 3.2132-21.0318-1.4188l21.0318 1.4188Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M44.2241 42.7983c-6.635 3.7974-15.899 3.2132-21.0318-1.4188"
    />
    <path
      fill="#fff"
      d="M22.149 36.7057c1.4189 2.7542 1.3771 6.4682-.2503 9.2641l.2503-9.2641Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M22.149 36.7057c1.4189 2.7542 1.3771 6.4682-.2503 9.2641"
    />
    <path
      fill="#1D1D1D"
      d="M29.641 29.7178c.2637-2.336-.5064-4.3407-1.7202-4.4778-1.2138-.137-2.4115 1.6455-2.6753 3.9815-.2638 2.3359.5064 4.3407 1.7202 4.4778 1.2138.137 2.4115-1.6456 2.6753-3.9815ZM38.6848 30.7493c.2637-2.336-.5064-4.3407-1.7202-4.4778-1.2138-.137-2.4115 1.6455-2.6753 3.9815-.2638 2.3359.5064 4.3407 1.7202 4.4778 1.2138.137 2.4115-1.6456 2.6753-3.9815Z"
    />
  </svg>
);
