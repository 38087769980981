import * as React from 'react';
export const CardArrivalIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={92}
    height={124}
    fill="none"
    viewBox="0 0 92 124"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.06}
      d="m68.1276 9.6915-5.5815 5.9439M45.188 3.6116v8.9458M23.8685 9.6915l5.5815 5.9439"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.062}
      d="M3.6117 76.6839c0-1.4317.748-2.7593 1.9726-3.5011l38.1425-23.1032a4.093 4.093 0 0 1 4.2412 0l38.1424 23.1032a4.0933 4.0933 0 0 1 1.9726 3.5011V115.3c0 2.261-1.8326 4.093-4.0932 4.093H7.705c-2.2607 0-4.0933-1.832-4.0933-4.093V76.6839Z"
    />
    <path
      fill="#99E2CD"
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3.06}
      d="M15.6506 33.7088c0-3.9894 3.234-7.2233 7.2233-7.2233h45.7476c3.9893 0 7.2233 3.2339 7.2233 7.2233v69.8252c0 3.989-3.234 7.223-7.2233 7.223H22.8739c-3.9893 0-7.2233-3.234-7.2233-7.223V33.7088Z"
    />
    <rect
      width={9.639}
      height={9.639}
      x={54.872}
      y={37.316}
      stroke="#1D1D1D"
      strokeWidth={2.4}
      rx={1.2}
    />
    <path
      fill="#fff"
      d="m45.7111 103.189 37.4976-18.4106-1.6256 32.6006H9.0245l.0012-32.6006L45.711 103.189Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.062}
      d="M3.6117 80.5962 45.8475 102.12 88.083 80.5962M79.36 119.523l-34.2486-17.78"
    />
  </svg>
);
