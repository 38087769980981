import * as React from 'react';
export const SecurityIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={70}
    height={96}
    fill="none"
    viewBox="0 0 70 96"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M45.484 52.7572V23.8249c0-6.009-4.8379-10.8995-10.8294-10.8995-5.9915 0-10.8294 4.8905-10.8294 10.8995v28.9323H13.501V23.8249c0-11.7875 9.4814-21.3239 21.1536-21.3239s21.1536 9.5364 21.1536 21.3239v28.9323H45.484Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      d="M45.484 52.7572V23.8249c0-6.009-4.8379-10.8995-10.8294-10.8995-5.9915 0-10.8294 4.8905-10.8294 10.8995v28.9323H13.501V23.8249c0-11.7875 9.4814-21.3239 21.1536-21.3239s21.1536 9.5364 21.1536 21.3239v28.9323H45.484Z"
    />
    <path
      stroke="#1D1D1D"
      strokeWidth={3.002}
      d="M45.484 52.7572V23.8249c0-6.009-4.8379-10.8995-10.8294-10.8995-5.9915 0-10.8294 4.8905-10.8294 10.8995v28.9323H13.501V23.8249c0-11.7875 9.4814-21.3239 21.1536-21.3239s21.1536 9.5364 21.1536 21.3239v28.9323H45.484Z"
    />
    <rect
      width={64.998}
      height={52.998}
      x={2.501}
      y={39.501}
      fill="#fff"
      rx={7.076}
    />
    <rect
      width={64.998}
      height={52.998}
      x={2.501}
      y={39.501}
      fill="#FFB938"
      fillOpacity={0.7}
      rx={7.076}
    />
    <rect
      width={64.998}
      height={52.998}
      x={2.501}
      y={39.501}
      stroke="#1D1D1D"
      strokeWidth={3.002}
      rx={7.076}
    />
    <path
      fill="#1D1D1D"
      d="M34.0054 73.4194c4.204.0356 7.969-.3444 7.947 2.2637-.0221 2.6081-3.4819 6.148-7.5753 6.1133-4.0935-.0346-7.4923-3.7012-7.4708-6.2406.0214-2.5394 2.895-2.1719 7.0991-2.1364Z"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M22.4892 67.1713c2.0214.6595 3.0393 2.628 2.9122 4.4969-.0483.709-.6621 1.2446-1.371 1.1963-.7089-.0482-1.2445-.662-1.1962-1.3709.0609-.8962-.4245-1.6445-1.1493-1.8781l-.015-.0048-.0149-.0052c-.949-.3318-1.9668.1806-2.2904 1.0918l-.006.0171-.3326.8588c-.2566.6626-1.0018.9918-1.6644.7352-.6626-.2566-.9917-1.0018-.7351-1.6644l.32-.8264c.7928-2.2012 3.2397-3.4423 5.5427-2.6463ZM49.2734 70.9471c-.1545-.9073-1.0936-1.5712-2.0567-1.3641l-.0069.0015c-.8806.1843-1.4454.9111-1.4894 1.7239-.0384.7095-.6447 1.2535-1.3542 1.2151-.7096-.0384-1.2536-.6447-1.2152-1.3542.1038-1.9173 1.4518-3.6664 3.5282-4.1027 2.3919-.5123 4.7277 1.096 5.1301 3.4442l.1551.8775c.1238.6997-.3432 1.3673-1.0429 1.491-.6997.1237-1.3672-.3432-1.4909-1.0429l-.1572-.8893Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M22.6523 75.8057c0 1.6937-1.7545 3.3783-4.3435 3.3783-2.589 0-4.3436-1.6846-4.3436-3.3783 0-1.6938 1.7546-3.3784 4.3436-3.3784 2.589 0 4.3435 1.6846 4.3435 3.3784Z"
    />
    <path
      fill="#F46147"
      fillOpacity={0.5}
      d="M22.6523 75.8057c0 1.6937-1.7545 3.3783-4.3435 3.3783-2.589 0-4.3436-1.6846-4.3436-3.3783 0-1.6938 1.7546-3.3784 4.3436-3.3784 2.589 0 4.3435 1.6846 4.3435 3.3784Z"
    />
    <path
      stroke="#1D1D1D"
      strokeWidth={1.93}
      d="M22.6523 75.8057c0 1.6937-1.7545 3.3783-4.3435 3.3783-2.589 0-4.3436-1.6846-4.3436-3.3783 0-1.6938 1.7546-3.3784 4.3436-3.3784 2.589 0 4.3435 1.6846 4.3435 3.3784Z"
    />
    <path
      fill="#fff"
      d="M56.4357 75.8057c0 1.6937-1.7546 3.3783-4.3436 3.3783-2.589 0-4.3435-1.6846-4.3435-3.3783 0-1.6938 1.7545-3.3784 4.3435-3.3784 2.589 0 4.3436 1.6846 4.3436 3.3784Z"
    />
    <path
      fill="#F46147"
      fillOpacity={0.5}
      d="M56.4357 75.8057c0 1.6937-1.7546 3.3783-4.3436 3.3783-2.589 0-4.3435-1.6846-4.3435-3.3783 0-1.6938 1.7545-3.3784 4.3435-3.3784 2.589 0 4.3436 1.6846 4.3436 3.3784Z"
    />
    <path
      stroke="#1D1D1D"
      strokeWidth={1.93}
      d="M56.4357 75.8057c0 1.6937-1.7546 3.3783-4.3436 3.3783-2.589 0-4.3435-1.6846-4.3435-3.3783 0-1.6938 1.7545-3.3784 4.3435-3.3784 2.589 0 4.3436 1.6846 4.3436 3.3784Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M6.4163 47.3165c0-2.3685 1.92-4.2885 4.2885-4.2885h1.1913c.9474 0 1.7154.768 1.7154 1.7154s-.768 1.7154-1.7154 1.7154h-1.1913a.8577.8577 0 0 0-.8577.8577v16.2489c0 .9475-.768 1.7155-1.7154 1.7155s-1.7154-.768-1.7154-1.7155V47.3165ZM14.8029 44.7434c0-.9474.768-1.7154 1.7154-1.7154h1.5883c.9474 0 1.7155.768 1.7155 1.7154s-.7681 1.7154-1.7155 1.7154h-1.5883c-.9474 0-1.7154-.768-1.7154-1.7154Z"
      clipRule="evenodd"
    />
  </svg>
);
