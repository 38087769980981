import * as React from 'react';
export const CashbagCleanIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={112}
    height={108}
    fill="none"
    viewBox="0 0 112 108"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <ellipse cx={55.727} cy={103.363} fill="#1D1D1D" rx={45} ry={4} />
    <path
      fill="#fff"
      d="M5 70.6378c0 25.3636 14.0909 33.8182 28.1818 33.8182h45.0909c14.0909 0 28.1823-8.4546 28.1823-33.8182 0-19.7273-14.0914-45.0909-42.2732-45.0909H47.2727C19.0909 25.5469 5 50.9105 5 70.6378Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M5 70.6378c0 25.3636 14.0909 33.8182 28.1818 33.8182h45.0909c14.0909 0 28.1823-8.4546 28.1823-33.8182 0-19.7273-14.0914-45.0909-42.2732-45.0909H47.2727C19.0909 25.5469 5 50.9105 5 70.6378Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M5 70.6378c0 25.3636 14.0909 33.8182 28.1818 33.8182h45.0909c14.0909 0 28.1823-8.4546 28.1823-33.8182 0-19.7273-14.0914-45.0909-42.2732-45.0909H47.2727C19.0909 25.5469 5 50.9105 5 70.6378Z"
    />
    <path fill="#fff" d="M45 3s0 22 11 22S67 3 67 3H45Z" />
    <path fill="#FFB938" fillOpacity={0.5} d="M45 3s0 22 11 22S67 3 67 3H45Z" />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M45 3s0 22 11 22S67 3 67 3H45Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M66 35c-.4723-3.1268-3.308-7.4236-6.5-9.5M46 35c.4723-3.1268 3.308-7.4236 6.5-9.5"
    />
    <path
      fill="#1D1D1D"
      d="M49.3245 70c0 .5523.4477 1 1 1h.2273c.5522 0 1-.4477 1-1v-2.2647a.9998.9998 0 0 1 .2403-.6503l.9838-1.1494c.0272-.0318.0733-.0069.098.0271l3.3497 4.624c.1881.2596.4893.4133.8099.4133h.1714c.8223 0 1.2932-.9374.8021-1.5971l-3.3211-4.4619a1 1 0 0 1 .0457-1.2511l2.6393-3.053c.5543-.6411.0988-1.6369-.7487-1.6369a.9899.9899 0 0 0-.7518.346l-4.2466 4.96c-.0231.027-.072.0425-.072.0069V60c0-.5523-.4478-1-1-1h-.2273c-.5523 0-1 .4477-1 1v10ZM60.1393 70c0 .5523.4478 1 1 1h.1382c.5523 0 1-.4477 1-1v-4.0914c0-1.44.5524-2.16 1.6571-2.16.5565 0 1.1025-.3113 1.2046-.8583l.0211-.1132c.0935-.5009-.2076-1.0008-.7165-1.0276a4.9877 4.9877 0 0 0-.2597-.0066c-.8507 0-1.5491.3417-1.9635.9142-.0205.0283-.0678.0451-.0678.0101v-.3586a.3943.3943 0 0 0-.3943-.3943h-.6192c-.5522 0-1 .4477-1 1V70Z"
    />
  </svg>
);
