import * as React from 'react';
export const ManInTheMirrorIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={261}
    height={139}
    fill="none"
    viewBox="0 0 261 139"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#000"
      d="m255.616 108.063-71.821 15.894L123 112.93l100.331-14.2052c10.927-1.5894 22.053-.6954 32.682 2.4832 3.378.994 3.179 6.06-.397 6.855Z"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M175.152 2.4665c-15.397-2.7814-30.198 7.1523-33.377 22.351l-18.874 88.0135 60.794 11.026 14.702-88.311c2.682-15.4967-7.649-30.298-23.245-33.0795Z"
    />
    <path
      fill="#fff"
      d="M54.755 27.4003c.298-2.7815 1.0927-9.2384-4.0728-12.2186C39.755 8.9234 16.4106 1.0757 12.0398 6.738 7.6689 12.301 24.755 25.7115 32.9007 39.8175c1.7881 3.1788 7.1523 2.7814 10.2318.9934"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M54.755 27.4003c.298-2.7815 1.0927-9.2384-4.0728-12.2186C39.755 8.9234 16.4106 1.0757 12.0398 6.738 7.6689 12.301 24.755 25.7115 32.9007 39.8175c1.7881 3.1788 7.1523 2.7814 10.2318.9934"
    />
    <path
      fill="#000"
      d="M14.7217 129.718c-11.6225 5.265 7.4503 8.643 49.5695 6.358 27.3179-1.391 49.5698-2.881 49.5698-6.358 0-3.576-22.2519-6.357-49.5698-6.357-27.3178 0-46.3907 4.966-49.5695 6.357Z"
    />
    <path
      fill="#FEE3B5"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M87.5363 122.268c4.6688 6.954 10.3311 8.94 14.1057 6.358 3.775-2.484 4.272-8.544-.397-15.497l-13.7087 9.139Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M116.444 63.6586c-8.146-39.6357-33.2786-57.9139-58.5104-52.7483-25.2317 5.1655-41.2251 31.8874-33.0794 71.5231 3.0794 15 8.3443 25.7286 15.0993 32.8806-4.2715 5.364-4.5695 10.431-1.5894 13.312 2.9801 2.88 9.6358 1.092 15.1987-3.875 8.543 3.378 18.0795 3.576 28.0132 1.59 25.331-5.166 43.013-22.947 34.868-62.6824Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="m96.4767 41.6057 5.1653 1.9868-3.1786 4.4702M105.119 47.1686c2.185 1.8874 5.662 1.6887 6.954 1.0927"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="m169.391 50.1488-3.775-3.9736 4.867-2.5827M146.841 39.8176l3.874 3.9735-4.867 2.6821M153.355 49.4376c1.469 2.9889 4.325 4.6031 8.155 1.2743"
    />
    <path
      fill="#fff"
      d="M60.0197 24.9169c-1.2914-2.4835-4.6689-6.6557-9.6357-5.861-12.0199 1.7881-37.351 6.8543-37.351 12.8146 0 5.3642 16.6887 8.0464 33.0794 15.6954 5.7616 2.6821 10.2318-1.6888 11.7219-4.9669"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M60.0197 24.9169c-1.2914-2.4835-4.6689-6.6557-9.6357-5.861-12.0199 1.7881-37.351 6.8543-37.351 12.8146 0 5.3642 16.6887 8.0464 33.0794 15.6954 5.7616 2.6821 10.2318-1.6888 11.7219-4.9669"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="m192.735 70.1155 15.298 34.8675"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M208.033 110.943c3.292 0 5.96-2.668 5.96-5.96s-2.668-5.9602-5.96-5.9602-5.96 2.6682-5.96 5.9602c0 3.292 2.668 5.96 5.96 5.96Z"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M92.9006 81.142c4.2716 8.3444 9.9334 11.4239 14.0064 9.3378 4.073-2.0861 4.967-8.4437.696-16.7881"
    />
    <path
      fill="#FEE3B5"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M116.443 63.6586c-.397-1.9868-.894-3.9735-1.39-5.8609-12.219 9.9337-36.6559 13.5099-51.6559 12.7152-14.8013-.7947-30.894-7.351-40.0331-19.1722-1.2914 9.1391-.9934 19.4702 1.3907 30.9934 3.0795 15 8.3444 25.7289 15.0994 32.8809-4.2716 5.364-4.5696 10.43-1.5894 13.311 2.9801 2.881 9.6357 1.093 15.1986-3.874 8.5431 3.377 18.0795 3.576 28.0133 1.589 25.4304-5.066 43.1124-22.847 34.9664-62.5824Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M19.2914 119.387 3 105.281l18.2781-21.0595 13.6093.6954 2.6821 13.4106-18.2781 21.0595Z"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M38.0661 77.3672c.3973 2.8808-.7947 5.861-3.1788 7.6491"
    />
    <path
      fill="#FEE3B5"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M135.119 55.9102c16.192 11.5232 41.325 11.7219 59.404 3.3775l-10.828 64.6693-60.794-11.027 12.218-57.0198Z"
    />
    <path
      fill="#1D1D1D"
      d="m21.8741 102.4 2.2848 1.987-1.4901 1.788-2.7815-2.384-.894 1.093 2.7814 2.384-1.49 1.788-2.7815-2.384-1.5894 1.987-1.7881-1.49 1.5894-1.987-2.8807-2.384 1.49-1.788 2.8808 2.384.8941-1.093-2.8808-2.3841 1.49-1.788 2.3841 1.9871 2.6821-9.2388 1.9868 1.6887-2.4834 7.7481 7.2516-3.7746 1.9868 1.6888-8.6424 4.1718Z"
    />
  </svg>
);
